import React, {useState, useEffect} from 'react'
import '../style.css'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../config";
import SweetAlert from 'sweetalert2-react'
import { MultiSelect } from "react-multi-select-component"
import {fetchData} from "../../../services";
import moment from "moment/moment";

export const PersonalDetails = ({display, toggleDisplay, compte}) =>  {
    const [errors, setErrors] = useState([])
    const [profile, setProfile] = useState({})
    const [genders, setGenders] = useState([])
    const [maritalStatus, setMaritalStatus] = useState([])
    const [civilities, setCivilities] = useState([])
    const [countries, setCountries] = useState([])
    const [cities, setCities] = useState([])
    const [countriesOptions, setCountriesOptions] = useState([])
    const [othersNationalities, setOthersNationalities] = useState([])
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        setProfile({
            first_name: compte?.first_name,
            last_name: compte?.last_name,
            date_of_birth: compte?.date_of_birth,
            city_of_birth: compte?.city_of_birth?.id_city,
            gender: compte?.gender?.id_gender,
            title: compte?.title?.id_situation,
            nationality: compte?.nationality?.id_country,
            civil_status: compte?.civil_status?.id_civility
        })

        let countries = []

        compte
            ?.others_nationalities
            ?.forEach(
                nationality => countries.push({
                    label: nationality.en_US,
                    value: nationality.id_country
                })
            )

        setOthersNationalities(countries)
        setDateOfBirth(moment(compte?.date_of_birth).format('YYYY-MM-DD'))
    }, [setProfile, setOthersNationalities, setDateOfBirth, compte])

    useEffect(() => {
        fetchData('genders')
            .then(data => setGenders(data.data))
    } , [setGenders])

    useEffect(() => {
        fetchData('marital_status')
            .then(data => setMaritalStatus(data.data))
    } , [setMaritalStatus])

    useEffect(() => {
        fetchData('countries')
            .then(data => {
                const countries = data.data
                let _countries = []

                setCountries(countries)

                countries.forEach(
                    country => _countries.push({label: country.en_US, value: country.id_country})
                )

                setCountriesOptions(_countries)
            })
    } , [setCountries, setCountriesOptions])

    useEffect(() => {
        fetchData('cities')
            .then(data => setCities(data.data))
    }, [setCities])

    useEffect(() => {
        fetchData('civilities')
            .then(data => setCivilities(data.data))
    } , [setCivilities])

    const handleSetGender = (e) => {
        setProfile({...profile, gender: parseInt(e.target.value)})
    }

    const handleSetMaritalStatus = (e) => {
        setProfile({...profile, title: parseInt(e.target.value)})
    }

    const handleSetCivilStatus = (e) => {
        setProfile({...profile, civil_status: parseInt(e.target.value)})
    }

    const handleSetNationality = (e) => {
        setProfile({...profile, nationality: parseInt(e.target.value)})
    }

    const handleSetDateOfBirth = (e) => {
        setProfile({...profile, date_of_birth: e.target.value})
    }

    const handleSetCityOfBirth = (e) => {
        setProfile({...profile, city_of_birth: e.target.value})
    }

    const handleSetFirstName = (e) => {
        setProfile({...profile, first_name: e.target.value})
    }

    const handleSetLastName = (e) => {
        setProfile({...profile, last_name: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData('personal_details', {
            method: 'put',
            body: JSON.stringify({
                first_name: profile.first_name,
                last_name: profile.last_name,
                date_of_birth: moment(profile.date_of_birth).format('DD/MM/YYYY'),
                city_of_birth: profile.city_of_birth,
                gender: profile.gender,
                title: profile.title,
                nationality: profile.nationality,
                civil_status: profile.civil_status,
                others_nationalities: othersNationalities.map(nationality => nationality.value)
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/details`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Personal Details</h5>

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>Civil Status</span>

                                <label className="mt-1">
                                    <i className="fa fa-male"></i>
                                    <select id="civil_status" value={profile.civil_status} onChange={handleSetCivilStatus} style={{ paddingLeft: '2em' }}>
                                        <option>Select civil status</option>

                                        {civilities.map(civility => {
                                            return <option key={civility.id_civility} value={civility.id_civility} defaultChecked={profile.civil_status === civility.id_civility}>
                                                {civility.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.civil_status && <span className="text-danger">
                                    {errors.first_name[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>First Name <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="far fa-user"></i>
                                    <input type="text" id="first_name" defaultValue={profile.first_name} onChange={handleSetFirstName} />
                                </label>

                                {errors.first_name && <span className="text-danger">
                                    {errors.first_name[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Last Name <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="far fa-user"></i>
                                    <input type="text" id="last_name" defaultValue={profile.last_name} onChange={handleSetLastName} />
                                </label>

                                {errors.last_name && <span className="text-danger">
                                    {errors.last_name[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Date Of Birth</span>

                                <label className="mt-1">
                                    <i className="far fa-calendar"></i>
                                    <input type="date" id="date" defaultValue={dateOfBirth} onChange={handleSetDateOfBirth} />
                                </label>

                                {errors.date_of_birth && <span className="text-danger">
                                    {errors.date_of_birth[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>City Of Birth</span>

                                <label className="mt-1">
                                    <i className="ti ti-flag"></i>
                                    <select style={{ paddingLeft: '2em' }} value={profile.city_of_birth} id="city_of_birth" onChange={handleSetCityOfBirth}>
                                        <option value="">Select city of birth</option>

                                        {cities.map(city => {
                                            return <option key={city.id_city} value={city.id_city} defaultChecked={profile.city_of_birth === city.id_city}>
                                                {city.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.city_of_birth && <span className="text-danger">
                                    {errors.city_of_birth[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Gender</span>

                                <label className="mt-1">
                                    <i className='fa fa-venus-mars'></i>
                                    <select id="gender" onChange={handleSetGender} value={profile.gender} style={{ paddingLeft: '2em' }}>
                                        <option>Select gender</option>

                                        {genders.map(gender => {
                                            return <option key={gender.id_gender} value={gender.id_gender} defaultChecked={profile.gender === gender.id_gender}>
                                                {gender.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.gender && <span className="text-danger">
                                    {errors.gender[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Marital Status</span>

                                <label className="mt-1">
                                    <i className="fa fa-ring"></i>
                                    <select id="marital_status" value={profile.title} onChange={handleSetMaritalStatus} style={{ paddingLeft: '2em' }}>
                                        <option>Select marital status</option>

                                        {maritalStatus.map(marital_status => {
                                            return <option key={marital_status.id_situation} value={marital_status.id_situation} defaultChecked={profile.title === marital_status.id_situation}>
                                                {marital_status.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.title && <span className="text-danger">
                                    {errors.title[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Nationality</span>

                                <label className="mt-1">
                                    <i className="far fa-flag"></i>
                                    <select id="nationality" value={profile.nationality} onChange={handleSetNationality} style={{ paddingLeft: '2em' }}>
                                        <option>Select nationality</option>

                                        {countries.map(country => {
                                            return <option key={country.id_country} value={country.id_country} defaultChecked={profile.nationality === country.id_country}>
                                                {country.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.nationality && <span className="text-danger">
                                    {errors.nationality[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Others Nationalities</span>

                                <div className="mt-1 multiselect">
                                    <i className="far fa-flag"></i>
                                    <MultiSelect options={countriesOptions} value={othersNationalities} onChange={setOthersNationalities} labelledBy="Select" />
                                </div>

                                {errors.others_nationalities && <span className="text-danger">
                                    {errors.others_nationalities[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6"></div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
