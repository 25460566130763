import React, {useState} from 'react'
import '../style.css'
import {PasswordConfirmation} from "./PasswordConfirmation";
import {getAuth} from "../../../services";

export const EmailAddress = ({display, toggleDisplay}) =>  {
    const [displayPasswordConfirmation, togglePasswordConfirmation] = useState(false)
    const [errors, setErrors] = useState([])
    const [profile, setProfile] = useState({
        new_email: '',
        email_confirmation: '',
    })

    const handleSetNewEmail = (e) => {
        setProfile({...profile, new_email: e.target.value})
    }

    const handleSetNewEmailConfirmation = (e) => {
        setProfile({...profile, email_confirmation: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (profile.new_email === '') {
            return setErrors({
                new_email: ['The new email is required']
            })
        }

        if (profile.email_confirmation === '') {
            return setErrors({
                email_confirmation: ['The new email confirmation is required']
            })
        }

        togglePasswordConfirmation(!displayPasswordConfirmation)
    }

    return <>
        <PasswordConfirmation
            display={displayPasswordConfirmation}
            toggleDisplay={togglePasswordConfirmation}
            profile={profile}
            setErrors={setErrors}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Email Address</h5>

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>Current Email <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-envelope"></i>
                                    <input type="email" id="current_email" defaultValue={getAuth().account.email_address} readOnly />
                                </label>
                            </div>

                            <div className="col-lg-6">
                                <span>New Email <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-envelope"></i>
                                    <input type="email" id="new_email" defaultValue={profile.new_email} onChange={handleSetNewEmail} />
                                </label>

                                {errors.new_email && <span className="text-danger">
                                    {errors.new_email[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>New Email Confirmation <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-envelope"></i>
                                    <input type="email" id="email_confirmation" defaultValue={profile.email_confirmation} onChange={handleSetNewEmailConfirmation} />
                                </label>

                                {errors.email_confirmation && <span className="text-danger">
                                    {errors.email_confirmation[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6"></div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
