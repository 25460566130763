import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/vendor/flaticon.css';
import './css/vendor/fontawesome.min.css';
import './css/vendor/themify-icons.css';

import Home from './pages/Home';
// import Home2 from './pages/Home2';
// import Home3 from './pages/Home3';

import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import ServicesDetails from './pages/ServicesDetails';
import BlogClassic from './pages/BlogClassic';
import BlogGrid from './pages/BlogGrid';
import Blog_details from './pages/BlogDetails';
import Contact1 from './pages/Contact1';
import Contact2 from './pages/Contact2';
import JobList from './pages/Job/JobList';
import JobDetails from './pages/Job/JobDetails';
import Career from './pages/Career';
import Error404 from './pages/Error404';
import Error405 from './pages/Error405';
import EmployersList from './pages/EmployersList';
import EmployersDetails from './pages/EmployersDetails';
import CandidateList from './pages/Candidate/CandidateList';
import CandidateDetails from './pages/Candidate/CandidateDetails';
import Login from './pages/Authentication/Login';
import Register from './pages/Authentication/Register';
import ResetPassword from "./pages/Authentication/ResetPassword";
import JobProfile from "./pages/Candidate/JobProfile";

import ScrollToTop from './components/layout/Gotop';

function App() {

    return (
        <div className="page">
            <Router basename="/">

                <Route exact path={`${process.env.PUBLIC_URL + '/'}`} component={ Home } />
                {/*<Route exact path={`${process.env.PUBLIC_URL + '/Home2'}`} component={ Home2 } /> */}
                {/*<Route exact path={`${process.env.PUBLIC_URL + '/Home3'}`} component={ Home3 } /> */}

                <Route exact path={`${process.env.PUBLIC_URL + '/AboutUs'}`} component={ AboutUs } />
                <Route exact path={`${process.env.PUBLIC_URL + '/Services'}`} component={ Services } />
                <Route exact path={`${process.env.PUBLIC_URL + '/ServicesDetails'}`} component={ ServicesDetails } />
                <Route exact path={`${process.env.PUBLIC_URL + '/BlogClassic'}`} component={ BlogClassic } />
                <Route exact path={`${process.env.PUBLIC_URL + '/BlogGrid'}`} component={ BlogGrid } />
                <Route exact path={`${process.env.PUBLIC_URL + '/Blog_details'}`} component={ Blog_details } />
                <Route exact path={`${process.env.PUBLIC_URL + '/Contact1'}`} component={ Contact1 } />
                <Route exact path={`${process.env.PUBLIC_URL + '/Contact2'}`} component={ Contact2 } />
                <Route exact path={`${process.env.PUBLIC_URL + '/job-list'}`} component={ JobList } />

                <Route exact path={`${process.env.PUBLIC_URL + '/job-details/:slug'}`} render={({ match }) => (
                    <JobDetails slug={match.params.slug} />
                )} />

                <Route exact path={`${process.env.PUBLIC_URL + '/Career'}`} component={ Career } />
                <Route exact path={`${process.env.PUBLIC_URL + '/Error404'}`} component={ Error404 } />
                <Route exact path={`${process.env.PUBLIC_URL + '/Error405'}`} component={ Error405 } />
                <Route exact path={`${process.env.PUBLIC_URL + '/EmployersList'}`} component={ EmployersList } />
                <Route exact path={`${process.env.PUBLIC_URL + '/EmployersDetails'}`} component={ EmployersDetails } />
                <Route exact path={`${process.env.PUBLIC_URL + '/candidate/list'}`} component={ CandidateList } />
                <Route exact path={`${process.env.PUBLIC_URL + '/candidate/details'}`} component={ CandidateDetails } />
                <Route exact path={`${process.env.PUBLIC_URL + '/candidate/job-profile'}`} component={ JobProfile } />
                <Route exact path={`${process.env.PUBLIC_URL + '/login'}`} component={ Login } />
                <Route exact path={`${process.env.PUBLIC_URL + '/register'}`} component={ Register } />
                <Route exact path={`${process.env.PUBLIC_URL + '/reset-password'}`} component={ ResetPassword } />

                <ScrollToTop />
            </Router>
        </div>
    );
}

export default App;


