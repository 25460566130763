import React, { Component } from 'react';
import Header from '../../components/layout/Header';
import PageHeader from "../../components/layout/PageHeader";
import { Footer } from '../../components/layout/Footer';
import {fetchData} from "../../services";
import {toggleSection} from "../../utils";
import {
    CertificationShow,
    CertificationEdit,
    ComputerSkillShow,
    ComputerSkillEdit,
    EducationEdit,
    EducationShow,
    SocialNetwork,
    LanguageSkillEdit,
    LanguageSkillShow,
    TrainingEdit,
    TrainingShow,
    ExperienceEdit,
    ExperienceShow,
    JobInterest,
    DeleteConfirmation
} from "../../components/modal";
import {Sidebar} from "./Sidebar";

export default class JobProfile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            profile: {},
            compte: {},
            errors: [],
            alert: {
                type: '',
                text: '',
                show: false,
                redirect: ''
            },
            certification: {
                id: null,
                list: [],
                show: false,
                edit: false,
            },
            computerSkill: {
                id: null,
                list: [],
                show: false,
                edit: false,
            },
            languageSkill: {
                id: null,
                list: [],
                show: false,
                edit: false,
            },
            education: {
                id: null,
                list: [],
                show: false,
                edit: false,
            },
            training: {
                id: null,
                list: [],
                show: false,
                edit: false,
            },
            experience: {
                id: null,
                list: [],
                show: false,
                edit: false,
            },
            displaySocialNetwork: false,
            displayJobInterest: false,
            displayDeleteConfirmation: false,
            deleteConfirmationUrl: ''
        }

        this.toggleCertificationShow = this.toggleCertificationShow.bind(this)
        this.toggleCertificationEdit = this.toggleCertificationEdit.bind(this)
        this.toggleComputerSkillShow = this.toggleComputerSkillShow.bind(this)
        this.toggleComputerSkillEdit = this.toggleComputerSkillEdit.bind(this)
        this.toggleEducationShow = this.toggleEducationShow.bind(this)
        this.toggleEducationEdit = this.toggleEducationEdit.bind(this)
        this.toggleLanguageSkillShow = this.toggleLanguageSkillShow.bind(this)
        this.toggleLanguageSkillEdit = this.toggleLanguageSkillEdit.bind(this)
        this.toggleTrainingShow = this.toggleTrainingShow.bind(this)
        this.toggleTrainingEdit = this.toggleTrainingEdit.bind(this)
        this.toggleExperienceShow = this.toggleExperienceShow.bind(this)
        this.toggleExperienceEdit = this.toggleExperienceEdit.bind(this)
        this.toggleSocialNetwork = this.toggleSocialNetwork.bind(this)
        this.toggleJobInterest = this.toggleJobInterest.bind(this)
        this.toggleDeleteConfirmation = this.toggleDeleteConfirmation.bind(this)
    }

    componentDidMount() {
        fetchData('profile')
            .then(data => {
                this.setState({
                    profile: data.data,
                    compte: data.data.compte,
                })
            })

        fetchData('certifications')
            .then(data => {
                this.setState({
                    certification: {
                        list: data
                    },
                })
            })

        fetchData('computer_skills')
            .then(data => {
                this.setState({
                    computerSkill: {
                        list: data
                    },
                })
            })

        fetchData('language_skills')
            .then(data => {
                this.setState({
                    languageSkill: {
                        list: data
                    },
                })
            })

        fetchData('educations')
            .then(data => {
                this.setState({
                    education: {
                        list: data
                    },
                })
            })

        fetchData('training')
            .then(data => {
                this.setState({
                    training: {
                        list: data
                    },
                })
            })

        fetchData('experience')
            .then(data => {
                this.setState({
                    experience: {
                        list: data
                    },
                })
            })
    }

    toggleCertificationShow(id) {
        this.setState(prevState => ({
            certification: {
                ...prevState.certification,
                id: id,
                show: !this.state.certification.show,
                edit: false
            }
        }))
    }

    toggleCertificationEdit(id = null) {
        this.setState(prevState => ({
            certification: {
                ...prevState.certification,
                id: id,
                edit: !this.state.certification.edit,
                show: false
            }
        }))
    }

    toggleComputerSkillShow(id) {
        this.setState(prevState => ({
            computerSkill: {
                ...prevState.computerSkill,
                id: id,
                show: !this.state.computerSkill.show,
                edit: false
            }
        }))
    }

    toggleComputerSkillEdit(id = null) {
        this.setState(prevState => ({
            computerSkill: {
                ...prevState.computerSkill,
                id: id,
                edit: !this.state.computerSkill.edit,
                show: false
            }
        }))
    }

    toggleLanguageSkillShow(id) {
        this.setState(prevState => ({
            languageSkill: {
                ...prevState.languageSkill,
                id: id,
                show: !this.state.languageSkill.show,
                edit: false
            }
        }))
    }

    toggleLanguageSkillEdit(id = null) {
        this.setState(prevState => ({
            languageSkill: {
                ...prevState.languageSkill,
                id: id,
                edit: !this.state.languageSkill.edit,
                show: false
            }
        }))
    }

    toggleEducationShow(id) {
        this.setState(prevState =>({
            education: {
                ...prevState.education,
                id: id,
                show: !this.state.education.show,
                edit: false
            }
        }))
    }

    toggleEducationEdit(id = null) {
        this.setState(prevState =>({
            education: {
                ...prevState.education,
                id: id,
                edit: !this.state.education.edit,
                show: false
            }
        }))
    }

    toggleTrainingShow(id) {
        this.setState(prevState => ({
            training: {
                ...prevState.training,
                id: id,
                show: !this.state.training.show,
                edit: false
            }
        }))
    }

    toggleTrainingEdit(id = null) {
        this.setState(prevState => ({
            training: {
                ...prevState.training,
                id: id,
                edit: !this.state.training.edit,
                show: false
            }
        }))
    }

    toggleExperienceShow(id) {
        this.setState(prevState => ({
            experience: {
                ...prevState.experience,
                id: id,
                show: !this.state.experience.show,
                edit: false
            }
        }))
    }

    toggleExperienceEdit(id = null) {
        this.setState(prevState => ({
            experience: {
                ...prevState.experience,
                id: id,
                edit: !this.state.experience.edit,
                show: false
            }
        }))
    }

    toggleSocialNetwork() {
        this.setState({displaySocialNetwork: !this.state.displaySocialNetwork})
    }

    toggleJobInterest() {
        this.setState({displayJobInterest: !this.state.displayJobInterest})
    }

    toggleDeleteConfirmation(url) {
        this.setState({
            deleteConfirmationUrl: url,
            displayDeleteConfirmation: !this.state.displayDeleteConfirmation
        })
    }

    render() {
        return <>
            <DeleteConfirmation
                display={this.state.displayDeleteConfirmation}
                toggleDisplay={this.toggleDeleteConfirmation}
                url={this.state.deleteConfirmationUrl}
            />

            {this.state.certification.show && <CertificationShow
                certificationId={this.state.certification.id}
                display={this.state.certification.show}
                toggleDisplay={this.toggleCertificationShow}
            />}

            {this.state.certification.edit && <CertificationEdit
                certificationId={this.state.certification.id}
                display={this.state.certification.edit}
                toggleDisplay={this.toggleCertificationEdit}
            />}

            {this.state.computerSkill.show && <ComputerSkillShow
                computerSkillId={this.state.computerSkill.id}
                display={this.state.computerSkill.show}
                toggleDisplay={this.toggleComputerSkillShow}
            />}

            {this.state.computerSkill.edit && <ComputerSkillEdit
                computerSkillId={this.state.computerSkill.id}
                display={this.state.computerSkill.edit}
                toggleDisplay={this.toggleComputerSkillEdit}
            />}

            {this.state.languageSkill.show && <LanguageSkillShow
                languageSkillId={this.state.languageSkill.id}
                display={this.state.languageSkill.show}
                toggleDisplay={this.toggleLanguageSkillShow}
            />}

            {this.state.languageSkill.edit && <LanguageSkillEdit
                languageSkillId={this.state.languageSkill.id}
                display={this.state.languageSkill.edit}
                toggleDisplay={this.toggleLanguageSkillEdit}
            />}

            {this.state.education.show && <EducationShow
                educationId={this.state.education.id}
                display={this.state.education.show}
                toggleDisplay={this.toggleEducationShow}
            />}

            {this.state.education.edit && <EducationEdit
                educationId={this.state.education.id}
                display={this.state.education.edit}
                toggleDisplay={this.toggleEducationEdit}
            />}

            {this.state.training.show && <TrainingShow
                trainingId={this.state.training.id}
                display={this.state.training.show}
                toggleDisplay={this.toggleTrainingShow}
            />}

            {this.state.training.edit && <TrainingEdit
                trainingId={this.state.training.id}
                display={this.state.training.edit}
                toggleDisplay={this.toggleTrainingEdit}
            />}

            {this.state.experience.show && <ExperienceShow
                experienceId={this.state.experience.id}
                display={this.state.experience.show}
                toggleDisplay={this.toggleExperienceShow}
            />}

            {this.state.experience.edit && <ExperienceEdit
                experienceId={this.state.experience.id}
                display={this.state.experience.edit}
                toggleDisplay={this.toggleExperienceEdit}
            />}

            {this.state.displaySocialNetwork && <SocialNetwork
                display={this.state.displaySocialNetwork}
                toggleDisplay={this.toggleSocialNetwork}
                compte={this.state.compte}
            />}

            {this.state.displayJobInterest && <JobInterest
                display={this.state.displayJobInterest}
                toggleDisplay={this.toggleJobInterest}
                compte={this.state.compte}
            />}

            <div className="site-main">
                <Header/>

                {/* PageHeader */}
                <PageHeader
                    title="Job Profile"
                    breadcrumb="Candidate"
                />
                {/* PageHeader end */}

                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <Sidebar
                                profile={this.state.profile}
                                toggleSocialNetwork={this.toggleSocialNetwork}
                                toggleJobInterest={this.toggleJobInterest}
                                certifications={this.state.certification.list}
                                computerSkills={this.state.computerSkill.list}
                                educations={this.state.education.list}
                                languageSkills={this.state.languageSkill.list}
                                trainings={this.state.training.list}
                                experiences={this.state.experience.list}
                            />

                            <div className="col-lg-8 content-area">
                                <div className="d-flex justify-content-end">
                                    <button className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => document.location.href = process.env.PUBLIC_URL + '/candidate/details'}>
                                        <i className="fa fa-arrow-left"></i>
                                        <span className="ms-3">Back to Candidate Details</span>
                                    </button>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Certifications</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#certifications" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="certifications">
                                                <table className="table table-sm mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="fw-bold">Title of qualification</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                </table>

                                                <div className="mb-3 ms-1">
                                                    {this.state.certification?.list?.data?.map(certification =>
                                                        <div key={certification.id} className="d-flex align-items-center justify-content-between border-1 border-bottom py-2">
                                                            <div>{certification.title_of_qualification}</div>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Show" onClick={() => this.toggleCertificationShow(certification.id)}>
                                                                    <i className="fa fa-eye fa-1x"></i>
                                                                </button>

                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Edit" onClick={() => this.toggleCertificationEdit(certification.id)}>
                                                                    <i className="fa fa-edit fa-1x mx-2"></i>
                                                                </button>

                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Remove" onClick={() => this.toggleDeleteConfirmation(`certifications/${certification.id}`)}>
                                                                    <i className="fa fa-times fa-lg"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <button className="w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleCertificationEdit()}>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Computer Skills</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#computer-skills" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="computer-skills">
                                                <table className="table table-sm mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="fw-bold">Knowledge domain</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                </table>

                                                <div className="mb-3 ms-1">
                                                {this.state.computerSkill?.list?.data?.map(computerSkill =>
                                                    <div key={computerSkill.id} className="d-flex align-items-center justify-content-between border-1 border-bottom py-2">
                                                        <div>{computerSkill?.knowledge_domain?.en_US}</div>

                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Show" onClick={() => this.toggleComputerSkillShow(computerSkill.id)}>
                                                                <i className="fa fa-eye fa-1x"></i>
                                                            </button>

                                                            <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Edit" onClick={() => this.toggleComputerSkillEdit(computerSkill.id)}>
                                                                <i className="fa fa-edit fa-1x mx-2"></i>
                                                            </button>

                                                            <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Remove" onClick={() => this.toggleDeleteConfirmation(`computer_skills/${computerSkill.id}`)}>
                                                                <i className="fa fa-times fa-lg"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <button className="w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleComputerSkillEdit()}>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Educations</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#educations" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="educations">
                                                <table className="table table-sm mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="fw-bold">Degree</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                </table>

                                                <div className="mb-3 ms-1">
                                                    {this.state.education?.list?.data?.map(education =>
                                                        <div key={education.id} className="d-flex align-items-center justify-content-between border-1 border-bottom py-2">
                                                            <div>{education.degree.en_US}</div>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Show" onClick={() => this.toggleEducationShow(education.id)}>
                                                                    <i className="fa fa-eye fa-1x"></i>
                                                                </button>

                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Edit" onClick={() => this.toggleEducationEdit(education.id)}>
                                                                    <i className="fa fa-edit fa-1x mx-2"></i>
                                                                </button>

                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Remove" onClick={() => this.toggleDeleteConfirmation(`educations/${education.id}`)}>
                                                                    <i className="fa fa-times fa-lg"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <button className="w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleEducationEdit()}>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Experiences</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#experiences" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="experiences">
                                                <table className="table table-sm mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="fw-bold">Job title</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                </table>

                                                <div className="mb-3 ms-1">
                                                    {this.state.experience?.list?.data?.map(experience =>
                                                        <div key={experience.id} className="d-flex align-items-center justify-content-between border-1 border-bottom py-2">
                                                            <div>{experience.job_title}</div>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Show" onClick={() => this.toggleExperienceShow(experience.id)}>
                                                                    <i className="fa fa-eye fa-1x"></i>
                                                                </button>

                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Edit" onClick={() => this.toggleExperienceEdit(experience.id)}>
                                                                    <i className="fa fa-edit fa-1x mx-2"></i>
                                                                </button>

                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Remove" onClick={() => this.toggleDeleteConfirmation(`experience/${experience.id}`)}>
                                                                    <i className="fa fa-times fa-lg"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <button className="w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleExperienceEdit()}>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Language Skills</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#language-skills" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="language-skills">
                                                <table className="table table-sm mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="fw-bold">Language</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                </table>

                                                <div className="mb-3 ms-1">
                                                    {this.state.languageSkill?.list?.data?.map(languageSkill =>
                                                        <div key={languageSkill.id} className="d-flex align-items-center justify-content-between border-1 border-bottom py-2">
                                                            <div>{languageSkill.language.en_US}</div>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Show" onClick={() => this.toggleLanguageSkillShow(languageSkill.id)}>
                                                                    <i className="fa fa-eye fa-1x"></i>
                                                                </button>

                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Edit" onClick={() => this.toggleLanguageSkillEdit(languageSkill.id)}>
                                                                    <i className="fa fa-edit fa-1x mx-2"></i>
                                                                </button>

                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Remove" onClick={() => this.toggleDeleteConfirmation(`language_skills/${languageSkill.id}`)}>
                                                                    <i className="fa fa-times fa-lg"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <button className="w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleLanguageSkillEdit()}>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Trainings</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#trainings" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="trainings">
                                                <table className="table table-sm mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="fw-bold">Training Domain</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                </table>

                                                <div className="mb-3 ms-1">
                                                    {this.state.training?.list?.data?.map(training =>
                                                        <div key={training.id} className="d-flex align-items-center justify-content-between border-1 border-bottom py-2">
                                                            <div>{training?.domain?.en_US}</div>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Show" onClick={() => this.toggleTrainingShow(training.id)}>
                                                                    <i className="fa fa-eye fa-1x"></i>
                                                                </button>

                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Edit" onClick={() => this.toggleTrainingEdit(training.id)}>
                                                                    <i className="fa fa-edit fa-1x mx-2"></i>
                                                                </button>

                                                                <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Remove" onClick={() => this.toggleDeleteConfirmation(`training/${training.id}`)}>
                                                                    <i className="fa fa-times fa-lg"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <button className="w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleTrainingEdit()}>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

                {/* action-section */}
                <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box icon-align-before-content style2">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                                <i className="flaticon flaticon-recruitment-5"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Incredible Recruitment & Staffing Agency</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end */}
                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                       href={process.env.PUBLIC_URL + '/'}>Hiring Now!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* action-section end */}

                <Footer/>

            </div>
        </>
    }
}
