import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";

export const ComputerSkillShow = ({computerSkillId, display, toggleDisplay}) => {
    const [computerSkill, setComputerSkill] = useState({})

    useEffect(() => {
        fetchData(`computer_skills/${computerSkillId}`)
            .then(data => setComputerSkill(data.data))
    }, [setComputerSkill, computerSkillId])

    return <div className={"modal-container " + (display ? 'show' : '')}>
        <div className="modal-content">
            <div className="modal-header">
                <h5>Computer Skill : {computerSkill?.knowledge_domain?.en_US}</h5>

                <button type="button" onClick={() => toggleDisplay()} title="Close">
                    <i className="fa fa-times fa-lg"></i>
                </button>
            </div>

            <div className="modal-body">
                <div className="row gy-3">
                    <div className="col-lg-6">
                        <p className="mb-0">Knowledge Domain</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {computerSkill?.knowledge_domain?.en_US ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Application System</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {computerSkill?.application_system_and_tools?.en_US ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Other Application System</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {computerSkill?.other_application_system ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Level</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {computerSkill?.level?.en_US ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Description</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {computerSkill?.description_of_relevant_expertise ?? '-'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
