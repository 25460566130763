import React, { Component } from 'react';
import Header from '../../components/layout/Header';
import PageHeader from "../../components/layout/PageHeader";
import { Footer } from '../../components/layout/Footer';
import {fetchData} from "../../services";
import moment from "moment";

export class JobList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            links: {},
            pagination: {},
            availablePost: '',
            typeOfMission: {
                id: null,
                list: []
            },
            salaryExpectation: {
                id: null,
                list: []
            },
            city: {
                id: null,
                list: []
            },
            jobType: {
                id: null,
                list: []
            },
            jobFunction: {
                id: null,
                list: []
            },
            sector: {
                id: null,
                list: []
            },
            startDeadline: '',
            endDeadline: ''
        }

        this.handleSetAvailablePost = this.handleSetAvailablePost.bind(this)
        this.handleSetTypeOfMission = this.handleSetTypeOfMission.bind(this)
        this.handleSetSalaryExpectation = this.handleSetSalaryExpectation.bind(this)
        this.handleSetCity = this.handleSetCity.bind(this)
        this.handleSetJobType = this.handleSetJobType.bind(this)
        this.handleSetJobFunction = this.handleSetJobFunction.bind(this)
        this.handleSetSector = this.handleSetSector.bind(this)
        this.handleSetStartDeadline = this.handleSetStartDeadline.bind(this)
        this.handleSetEndDeadline = this.handleSetEndDeadline.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePagination = this.handlePagination.bind(this)
        this.handleSort = this.handleSort.bind(this)
    }

    componentDidMount() {
        fetchData('job_postings', {}, false)
            .then(data => {
                this.setState({
                    jobs: data.data,
                    links: data.links,
                    pagination: data.meta,
                })
            })

        fetchData('types_of_missions', {}, false)
            .then(data => {
                this.setState({
                    typeOfMission: {
                        list: data.data
                    },
                })
            })

        fetchData('salary_expectations', {}, false)
            .then(data => {
                this.setState({
                    salaryExpectation: {
                        list: data.data
                    },
                })
            })

        fetchData('cities', {}, false)
            .then(data => {
                this.setState({
                    city: {
                        list: data.data
                    },
                })
            })

        fetchData('type_of_contract', {}, false)
            .then(data => {
                this.setState({
                    jobType: {
                        list: data.data
                    },
                })
            })

        fetchData('functions', {}, false)
            .then(data => {
                this.setState({
                    jobFunction: {
                        list: data.data
                    },
                })
            })

        fetchData('sectors', {}, false)
            .then(data => {
                this.setState({
                    sector: {
                        list: data.data
                    },
                })
            })
    }

    handleSetAvailablePost(e) {
        this.setState({
            availablePost: e.target.value
        })
    }

    handleSetTypeOfMission(e) {
        this.setState(prevState => ({
            typeOfMission: {
                ...prevState.typeOfMission, id: e.target.value,
            }
        }))
    }

    handleSetSalaryExpectation(e) {
        this.setState(prevState => ({
            salaryExpectation: {
                ...prevState.salaryExpectation, id: e.target.value,
            }
        }))
    }

    handleSetCity(e) {
        this.setState(prevState => ({
            city: {
                ...prevState.city, id: e.target.value,
            }
        }))
    }

    handleSetJobType(e) {
        this.setState(prevState => ({
            jobType: {
                ...prevState.jobType, id: e.target.value,
            }
        }))
    }

    handleSetJobFunction(e) {
        this.setState(prevState => ({
            jobFunction: {
                ...prevState.jobFunction, id: e.target.value,
            }
        }))
    }

    handleSetSector(e) {
        this.setState(prevState => ({
            sector: {
                ...prevState.sector, id: e.target.value,
            }
        }))
    }

    handleSetStartDeadline(e) {
        this.setState({
            startDeadline: e.target.value
        })
    }

    handleSetEndDeadline(e) {
        this.setState({
            endDeadline: e.target.value
        })
    }

    handleSubmit(e) {
        e.target.innerHTML = '<i class="fa fa-spinner fa-spin"></i> Apply'

        fetchData('job_postings/search', {
            method: 'post',
            body: JSON.stringify({
                available_post: this.state.availablePost,
                type_mission_id: parseInt(this.state.typeOfMission.id),
                salary_expectation_id: parseInt(this.state.salaryExpectation.id),
                city_assignment_id: parseInt(this.state.city.id),
                job_type_id: parseInt(this.state.jobType.id),
                function_id: parseInt(this.state.jobFunction.id),
                sector_id: parseInt(this.state.sector.id),
                start_deadline: this.state.startDeadline ? moment(this.state.startDeadline).format('DD/MM/YYYY') : null,
                end_deadline: this.state.endDeadline ? moment(this.state.endDeadline).format('DD/MM/YYYY') : null,
            })
        }, false)
            .then(data => {
                this.setState({
                    jobs: data.data,
                    links: data.links,
                    pagination: data.meta,
                })

                e.target.innerHTML = 'Apply'

                window.scrollTo(0, 0)
            })
    }

    handlePagination(page, e) {
        const btnText = e.target.innerHTML
        e.target.innerHTML = '<i class="fa fa-spinner fa-spin"></i>'

        fetchData('job_postings?page=' + page, {}, false)
            .then(data => {
                this.setState({
                    jobs: data.data,
                    links: data.links,
                    pagination: data.meta,
                })

                e.target.innerHTML = btnText

                window.scrollTo(0, 0)
            })
    }

    handleSort(e) {
        this.setState({
            jobs: this.state.jobs.sort((a, b) => {
                return e.target.value === 'oldest' ? b.publication_date - a.publication_date : a.publication_date - b.publication_date
            })
        })
    }

    render() {
        return (
            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="job list"
                    breadcrumb="Jobs"
                    className="pb-65 pb-lg-0"
                />
                {/* PageHeader end */}

                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                <div className="job_list-widget">
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title">
                                            <i className="flaticon flaticon-job-search-1"></i> Search Job
                                        </h3>

                                        <div className="wrap-form">
                                            <span className="mb-2">Job title or keywords</span>
                                            <label>
                                                <i className="fa fa-search"></i>
                                                <input type="search" id="available_post" defaultValue={this.state.availablePost} onChange={this.handleSetAvailablePost} />
                                            </label>
                                        </div>

                                        <div className="wrap-form" style={{ marginBlock: '1em' }}>
                                            <span className="mb-2">Type Of Mission</span>
                                            <label>
                                                <i className="fa fa-user-tie"></i>
                                                <select style={{ paddingLeft: '2em' }} id="type_of_mission" value={this.state.typeOfMission?.id} onChange={this.handleSetTypeOfMission}>
                                                    <option defaultValue=""></option>

                                                    {this.state.typeOfMission?.list.map(typeOfMission => {
                                                        return <option key={typeOfMission.id_mission_type} value={typeOfMission.id_mission_type} defaultChecked={this.state.typeOfMission?.id === typeOfMission.id_mission_type}>
                                                            {typeOfMission.en_US}
                                                        </option>
                                                    })}
                                                </select>
                                            </label>
                                        </div>

                                        <div className="wrap-form">
                                            <span>Salary Expectation</span>
                                            <label>
                                                <i className="fa fa-money-bill"></i>
                                                <select style={{ paddingLeft: '2em' }} id="salary_expectation" value={this.state.salaryExpectation?.id} onChange={this.handleSetSalaryExpectation}>
                                                    <option defaultValue=""></option>

                                                    {this.state.salaryExpectation?.list.map(salaryExpectation => {
                                                        return <option key={salaryExpectation.id_salary_expectation} value={salaryExpectation.id_salary_expectation} defaultChecked={this.state.salaryExpectation?.id === salaryExpectation.id_salary_expectation}>
                                                            {salaryExpectation.en_US}
                                                        </option>
                                                    })}
                                                </select>
                                            </label>
                                        </div>

                                        <div className="wrap-form" style={{ marginBlock: '1em' }}>
                                            <span>Location</span>
                                            <label>
                                                <i className="fa fa-map-marker-alt"></i>
                                                <select style={{ paddingLeft: '2em' }} id="city" value={this.state.city?.id} onChange={this.handleSetCity}>
                                                    <option defaultValue=""></option>

                                                    {this.state.city?.list.map(city => {
                                                        return <option key={city.id_city} value={city.id_city} defaultChecked={this.state.city?.id === city.id_city}>
                                                            {city.en_US}
                                                        </option>
                                                    })}
                                                </select>
                                            </label>
                                        </div>

                                        <div className="wrap-form">
                                            <span>Job Type</span>
                                            <label>
                                                <i className="fa fa-user-tie"></i>
                                                <select style={{ paddingLeft: '2em' }} id="job_type" value={this.state.jobType?.id} onChange={this.handleSetJobType}>
                                                    <option defaultValue=""></option>

                                                    {this.state.jobType?.list.map(jobType => {
                                                        return <option key={jobType.id_job_type} value={jobType.id_job_type} defaultChecked={this.state.jobType?.id === jobType.id_job_type}>
                                                            {jobType.en_US}
                                                        </option>
                                                    })}
                                                </select>
                                            </label>
                                        </div>

                                        <div className="wrap-form" style={{ marginBlock: '1em' }}>
                                            <span>Function</span>
                                            <label>
                                                <i className="fa fa-user-tie"></i>
                                                <select style={{ paddingLeft: '2em' }} id="job_function" value={this.state.jobFunction?.id} onChange={this.handleSetJobFunction}>
                                                    <option defaultValue=""></option>

                                                    {this.state.jobFunction?.list.map(jobFunction => {
                                                        return <option key={jobFunction.id_function} value={jobFunction.id_function} defaultChecked={this.state.jobFunction?.id === jobFunction.id_function}>
                                                            {jobFunction.en_US}
                                                        </option>
                                                    })}
                                                </select>
                                            </label>
                                        </div>

                                        <div className="wrap-form">
                                            <span>Sector</span>
                                            <label>
                                                <i className="fa fa-building"></i>
                                                <select style={{ paddingLeft: '2em' }} id="sector" value={this.state.sector?.id} onChange={this.handleSetSector}>
                                                    <option defaultValue=""></option>

                                                    {this.state.sector?.list.map(sector => {
                                                        return <option key={sector.id_sector} value={sector.id_sector} defaultChecked={this.state.sector?.id === sector.id_sector}>
                                                            {sector.en_US}
                                                        </option>
                                                    })}
                                                </select>
                                            </label>
                                        </div>

                                        <div className="wrap-form" style={{ marginBlock: '1em' }}>
                                            <span>Start Deadline</span>

                                            <label>
                                                <i className="fa fa-calendar"></i>
                                                <input type="date" id="start_deadline" defaultValue={this.state.startDeadline} onChange={this.handleSetStartDeadline} />
                                            </label>
                                        </div>

                                        <div className="wrap-form">
                                            <span>End Deadline</span>

                                            <label>
                                                <i className="fa fa-calendar"></i>
                                                <input type="date" id="end_deadline" defaultValue={this.state.endDeadline} onChange={this.handleSetEndDeadline} />
                                            </label>
                                        </div>
                                    </aside>

                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <button className="ttm-btn w-100 ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                type="submit" onClick={this.handleSubmit}>Apply</button>

                                        <button className="ttm-btn w-100 ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-dark"
                                                type="submit" onClick={() => document.location.reload()}>Reset</button>
                                    </div>
                                </div>

                                <aside className="widget widget-banner text-theme-WhiteColor">
                                    <div className="ttm-col-bgcolor-yes bg-theme-DarkColor text-theme-WhitColor col-bg-img-seven ttm-col-bgimage-yes ttm-bg p-40">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor" style={{ backgroundImage: 'url(https://via.placeholder.com/875x583?text=875x583+col-bgimage-7.jpg)' }}>
                                            <div className="ttm-col-wrapper-bg-layer-inner bg-theme-DarkColor"></div>
                                        </div>
                                        <div className="layer-content text-center">
                                            <div className="widget-banner-inner">
                                                <h3 className="mb-15">Make a Difference with Online Resume!</h3>
                                                <p className="mb-30">Your Resume in Minutes with Jobs Resume Assistant is Ready!</p>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                                href={process.env.PUBLIC_URL + '/'}>appoinments!</a>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>

                            <div className="col-lg-8 content-area">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="showing-result-block d-sm-flex align-items-center justify-content-between">
                                            <span className="showing-result">
                                                Showing {this.state.pagination.from ?? 0}–{this.state.pagination.to ?? 0} of {this.state.pagination.total} Jobs Results :
                                            </span>

                                            {/*<form>*/}
                                            {/*    <div className="sort-by">Sort By:*/}
                                            {/*        <select defaultValue="newest" onChange={this.handleSort}>*/}
                                            {/*            <option value="newest">Newest</option>*/}
                                            {/*            <option value="oldest">Oldest</option>*/}
                                            {/*        </select>*/}
                                            {/*    </div>*/}
                                            {/*</form>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        {
                                            this.state.jobs.map(job => {
                                                return <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>
                                                                <a href={process.env.PUBLIC_URL + '/job-details/' + job.slug}>
                                                                    {job.available_post}
                                                                </a>
                                                            </h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Published {moment(job.publication_date).fromNow()}</p>
                                                        </div>
                                                        <div className="featured-bottom pt-0">
                                                            <div className="job-meta d-flex align-items-center gap-3">
                                                                <p className="mb-0"><i className="fa fa-map-marker-alt me-2" style={{ color: '#ff8d00'}}></i>{job.city.en_US}</p>
                                                                <p className="mb-0"><i className="fa fa-user-tie me-2" style={{ color: '#ff8d00'}}></i>{job.job_type.en_US}</p>
                                                                {job.salary_expectation &&
                                                                    <p className="mb-0"><i className="fa fa-money-bill me-2" style={{ color: '#ff8d00'}}></i>{job.salary_expectation?.en_US}</p>
                                                                }
                                                            </div>
                                                            <div className="job-time">
                                                                <button className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => document.location.href = process.env.PUBLIC_URL + '/job-details/' + job.slug}>
                                                                    Apply
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="job-pagination-block">
                                            {this.state.pagination.current_page > 1 && <a className="page-nav-link" onClick={e => this.handlePagination(this.state.pagination.current_page - 1, e)}>prev</a>}
                                            <a className="page-nav-link current">
                                                Page {this.state.pagination.current_page} of {this.state.pagination.last_page}
                                            </a>
                                            {this.state.pagination.current_page < this.state.pagination.last_page && <a className="page-nav-link" onClick={e => this.handlePagination(this.state.pagination.current_page + 1, e)}>next</a>}
                                        </div>
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>


                {/* action-section */}
                <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box icon-align-before-content style2">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                                <i className="flaticon flaticon-recruitment-5"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Incredible Recruitment & Staffing Agency</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end */}
                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                    href={process.env.PUBLIC_URL + '/'}>Hiring Now!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* action-section end */}

                
            <Footer/>
                        
            </div>
          )
      }
   }

export default JobList;