import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";
import SweetAlert from 'sweetalert2-react'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../../config";

export const LanguageSkillEdit = ({languageSkillId, display, toggleDisplay}) => {
    const url = languageSkillId === null ? 'language_skills' : `language_skills/${languageSkillId}`
    const method = languageSkillId === null ? 'post' : 'put'

    const [languageSkill, setLanguageSkill] = useState({})
    const [languages, setLanguages] = useState([])
    const [levels, setLevels] = useState([])
    const [errors, setErrors] = useState([])
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        if (languageSkillId !== null) {
            fetchData(`language_skills/${languageSkillId}`)
                .then(data => setLanguageSkill(data.data))
        }
    }, [setLanguageSkill, languageSkillId])

    useEffect(() => {
        fetchData('languages')
            .then(data => setLanguages(data.data))
    }, [setLanguages])

    useEffect(() => {
        fetchData('language_levels')
            .then(data => setLevels(data.data))
    }, [setLevels])

    const handleSetLanguage = (e) => {
        setLanguageSkill({...languageSkill, language: parseInt(e.target.value)})
    }

    const handleSetLevel = (e) => {
        setLanguageSkill({...languageSkill, level: parseInt(e.target.value)})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData(url, {
            method: method,
            body: JSON.stringify({
                language: isNaN(languageSkill.language) ? languageSkill.language?.id_language : languageSkill.language,
                level: isNaN(languageSkill.level) ? languageSkill.level?.id_language_level : languageSkill.level,
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/job-profile`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    {languageSkillId !== null && <h5>Language Skill : {languageSkill?.language?.en_US}</h5>}
                    {languageSkillId === null && <h5>Add Language Skill</h5>}

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>Language</span>

                                <label className="mt-1">
                                    <i className="fa fa-spell-check"></i>
                                    <select style={{ paddingLeft: '2em' }} id="language" value={languageSkill?.language?.id_language} onChange={handleSetLanguage}>
                                        <option value="">Select language</option>

                                        {languages.map(language => {
                                            return <option key={language.id_language} value={language.id_language} defaultChecked={languageSkill?.language === language.id_language}>
                                                {language.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.language && <span className="text-danger">
                                    {errors.language[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Language Proficiency</span>

                                <label className="mt-1">
                                    <i className="fa fa-list-ol"></i>
                                    <select style={{ paddingLeft: '2em' }} id="level" value={languageSkill?.level?.id_language_level} onChange={handleSetLevel}>
                                        <option value="">Select language proficiency</option>

                                        {levels?.map(level => {
                                            return <option key={level.id_language_level} value={level.id_language_level} defaultChecked={languageSkill?.level === level.id_language_level}>
                                                {level.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.level && <span className="text-danger">
                                    {errors.level[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
