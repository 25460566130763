import React, { Component } from 'react';
import Header from '../../components/layout/Header';
import PageHeader from "../../components/layout/PageHeader";
import { Footer } from '../../components/layout/Footer';
import {fetchData} from "../../services";
import {
    PersonalDetails,
    ContactDetails,
    FurtherInformation,
    AddressAndLocation,
    AdditionalInformation,
    EmailAddress,
    Password,
    SocialNetwork,
    JobInterest,
    ProfilePicture,
    CurriculumVitae,
    CoverLetter
} from "../../components/modal";
import {arrayToString, toggleSection} from "../../utils";
import {Sidebar} from "./Sidebar";
import moment from "moment/moment";
import SweetAlert from 'sweetalert2-react'

export default class CandidateDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            profile: {},
            compte: {},
            errors: [],
            certifications: [],
            computerSkills: [],
            educations: [],
            languageSkills: [],
            trainings: [],
            experiences: [],
            displayProfilePicture: false,
            displayCurriculumVitae: false,
            displayCoverLetter: false,
            displayPersonalDetails: false,
            displayContactDetails: false,
            displayFurtherInformation: false,
            displayAdditionalInformation: false,
            displayEmailAddress: false,
            displayPassword: false,
            displaySocialNetwork: false,
            displayJobInterest: false,
        }

        this.togglePersonalDetails = this.togglePersonalDetails.bind(this)
        this.toggleContactDetails = this.toggleContactDetails.bind(this)
        this.toggleAddressAndLocation = this.toggleAddressAndLocation.bind(this)
        this.toggleFurtherInformation = this.toggleFurtherInformation.bind(this)
        this.toggleAdditionalInformation = this.toggleAdditionalInformation.bind(this)
        this.toggleEmailAddress = this.toggleEmailAddress.bind(this)
        this.togglePassword = this.togglePassword.bind(this)
        this.toggleSocialNetwork = this.toggleSocialNetwork.bind(this)
        this.toggleJobInterest = this.toggleJobInterest.bind(this)
        this.toggleProfilePicture = this.toggleProfilePicture.bind(this)
        this.toggleCurriculumVitae = this.toggleCurriculumVitae.bind(this)
        this.toggleCoverLetter = this.toggleCoverLetter.bind(this)
    }

    componentDidMount() {
        fetchData('profile')
            .then(data => {
                this.setState({
                    profile: data.data,
                    compte: data.data.compte,
                })
            })

        fetchData('certifications')
            .then(data => {
                this.setState({
                    certifications: data,
                })
            })

        fetchData('computer_skills')
            .then(data => {
                this.setState({
                    computerSkills: data,
                })
            })

        fetchData('language_skills')
            .then(data => {
                this.setState({
                    languageSkills: data,
                })
            })

        fetchData('educations')
            .then(data => {
                this.setState({
                    educations: data,
                })
            })

        fetchData('training')
            .then(data => {
                this.setState({
                    trainings: data,
                })
            })

        fetchData('experience')
            .then(data => {
                this.setState({
                    experiences: data,
                })
            })
    }

    togglePersonalDetails() {
        this.setState({displayPersonalDetails: !this.state.displayPersonalDetails})
    }

    toggleContactDetails() {
        this.setState({displayContactDetails: !this.state.displayContactDetails})
    }

    toggleAddressAndLocation() {
        this.setState({displayAddressAndLocation: !this.state.displayAddressAndLocation})
    }

    toggleFurtherInformation() {
        this.setState({displayFurtherInformation: !this.state.displayFurtherInformation})
    }

    toggleAdditionalInformation() {
        this.setState({displayAdditionalInformation: !this.state.displayAdditionalInformation})
    }

    toggleEmailAddress() {
        this.setState({displayEmailAddress: !this.state.displayEmailAddress})
    }

    togglePassword() {
        this.setState({displayPassword: !this.state.displayPassword})
    }

    toggleSocialNetwork() {
        this.setState({displaySocialNetwork: !this.state.displaySocialNetwork})
    }

    toggleJobInterest() {
        this.setState({displayJobInterest: !this.state.displayJobInterest})
    }

    toggleProfilePicture() {
        this.setState({displayProfilePicture: !this.state.displayProfilePicture})
    }

    toggleCurriculumVitae() {
        this.setState({displayCurriculumVitae: !this.state.displayCurriculumVitae})
    }

    toggleCoverLetter() {
        this.setState({displayCoverLetter: !this.state.displayCoverLetter})
    }

    render() {
        return <>
            <SweetAlert
                show={alert.show}
                text={alert.text}
                type={alert.type}
                onConfirm={() => document.location.href = alert.redirect}
            />

            {this.state.displayPersonalDetails && <PersonalDetails
                display={this.state.displayPersonalDetails}
                toggleDisplay={this.togglePersonalDetails}
                compte={this.state.compte}
            />}

            {this.state.displayContactDetails && <ContactDetails
                display={this.state.displayContactDetails}
                toggleDisplay={this.toggleContactDetails}
                compte={this.state.compte}
            />}

            {this.state.displayFurtherInformation && <FurtherInformation
                display={this.state.displayFurtherInformation}
                toggleDisplay={this.toggleFurtherInformation}
                compte={this.state.compte}
            />}

            {this.state.displayAddressAndLocation && <AddressAndLocation
                display={this.state.displayAddressAndLocation}
                toggleDisplay={this.toggleAddressAndLocation}
                compte={this.state.compte}
            />}

            {this.state.displayAdditionalInformation && <AdditionalInformation
                display={this.state.displayAdditionalInformation}
                toggleDisplay={this.toggleAdditionalInformation}
                compte={this.state.compte}
            />}

            {this.state.displayEmailAddress && <EmailAddress
                display={this.state.displayEmailAddress}
                toggleDisplay={this.toggleEmailAddress}
            />}

            {this.state.displayPassword && <Password
                display={this.state.displayPassword}
                toggleDisplay={this.togglePassword}
            />}

            {this.state.displayProfilePicture && <ProfilePicture
                display={this.state.displayProfilePicture}
                toggleDisplay={this.toggleProfilePicture}
            />}

            {this.state.displayCurriculumVitae && <CurriculumVitae
                display={this.state.displayCurriculumVitae}
                toggleDisplay={this.toggleCurriculumVitae}
            />}

            {this.state.displayCoverLetter && <CoverLetter
                display={this.state.displayCoverLetter}
                toggleDisplay={this.toggleCoverLetter}
            />}

            {this.state.displaySocialNetwork && <SocialNetwork
                display={this.state.displaySocialNetwork}
                toggleDisplay={this.toggleSocialNetwork}
                compte={this.state.compte}
            />}

            {this.state.displayJobInterest && <JobInterest
                display={this.state.displayJobInterest}
                toggleDisplay={this.toggleJobInterest}
                compte={this.state.compte}
            />}

            <div className="site-main">
                <Header/>

                {/* PageHeader */}
                <PageHeader
                    title="Candidate Details"
                    breadcrumb="Candidate"
                />
                {/* PageHeader end */}

                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <Sidebar
                                profile={this.state.profile}
                                toggleSocialNetwork={this.toggleSocialNetwork}
                                toggleJobInterest={this.toggleJobInterest}
                                toggleCurriculumVitae={this.toggleCurriculumVitae}
                                toggleCoverLetter={this.toggleCoverLetter}
                                certifications={this.state.certifications}
                                computerSkills={this.state.computerSkills}
                                educations={this.state.educations}
                                languageSkills={this.state.languageSkills}
                                trainings={this.state.trainings}
                                experiences={this.state.experiences}
                            />

                            <div className="col-lg-8 content-area">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="candidate-data">
                                            <div className="candidate-img position-relative">
                                                <img src={this.state.profile.compte?.default_profile_picture ?? this.state.profile.compte?.profile_picture} alt='' />

                                                <button className="position-absolute submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleProfilePicture()}>
                                                    Edit
                                                </button>
                                            </div>
                                            <div className="candidate-caption">
                                                <h5>{this.state.profile.compte?.first_name} {this.state.profile.compte?.last_name}</h5>
                                                <div className="meta-line">
                                                    <span><i className="fa fa-mobile-alt"></i> {this.state.profile.compte?.mobile_number}</span>
                                                    <span><i className="fab fa-whatsapp"></i> {this.state.profile.compte?.whatsapp_number}</span>
                                                    <span><i className="far fa-envelope"></i> {this.state.profile.email}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Further Information</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#further-information" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="further-information">
                                                <p className="mb-3">
                                                    {this.state.profile.compte?.further_information ?? '-'}
                                                </p>

                                                <div className="row">
                                                    <div className="col-lg-2">
                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleFurtherInformation()}>
                                                            Edit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Personal Details</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#personal-details" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="personal-details">
                                                <div className="row gy-3">
                                                    <div className="col-lg-4">
                                                        <p className="mb-0">Civil Status</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.civil_status?.en_US ?? '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="mb-0">First Name</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.first_name}
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="mb-0">Last Name</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.last_name}
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="mb-0">Date Of Birth</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {!this.state.profile.compte?.date_of_birth && '-/-/-'}
                                                            {this.state.profile.compte?.date_of_birth && moment(this.state.profile.compte?.date_of_birth).format('LL')}
                                                        </p>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <p className="mb-0">City Of Birth</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.city_of_birth?.en_US ?? '-'}
                                                        </p>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <p className="mb-0">Gender</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.gender?.en_US ?? '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="mb-0">Marital Status</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.title?.en_US ?? '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="mb-0">Nationality</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.nationality?.en_US ?? '-'}
                                                        </p>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <p className="mb-0">Others Nationalities</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {arrayToString(this.state.profile.compte?.others_nationalities)}
                                                        </p>
                                                    </div>

                                                    <div className="col-lg-2">
                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.togglePersonalDetails()}>
                                                            Edit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Contact Details</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#contact-details" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="contact-details">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <p className="mb-0">Mobile Number</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.mobile_number}
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="mb-0">Whatsapp Number</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.whatsapp_number}
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4 mb-4">
                                                        <p className="mb-0">Professional Number</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.professional_number ?? '-'}
                                                        </p>
                                                    </div>

                                                    <div className="col-lg-4 mb-4">
                                                        <p className="mb-0">Website</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.website_blog ?? '-'}
                                                        </p>
                                                    </div>

                                                    <div className="col-lg-4 mb-4">
                                                        <p className="mb-0">Secondary Email</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.secondary_email ?? '-'}
                                                        </p>
                                                    </div>

                                                    <div className="col-lg-4"></div>

                                                    <div className="col-lg-2">
                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleContactDetails()}>
                                                            Edit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Address & Location</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#address-location" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="address-location">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <p className="mb-0">State</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.address?.address_city?.en_US ?? '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <p className="mb-0">Other State</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.address?.other_city_address ?? '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4 mb-4">
                                                        <p className="mb-0">Street Address</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.address?.street_address ?? '-'}
                                                        </p>
                                                    </div>

                                                    <div className="col-lg-4 mb-4">
                                                        <p className="mb-0">ZIP Postal Code</p>
                                                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {this.state.profile.compte?.address?.ZIP_code ?? '-'}
                                                        </p>
                                                    </div>

                                                    <div className="col-lg-4"></div>
                                                    <div className="col-lg-4"></div>

                                                    <div className="col-lg-2">
                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleAddressAndLocation()}>
                                                            Edit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Additional Information</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#additional-information" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="additional-information">
                                                <div className="row gy-3">
                                                    <div className="col-6">
                                                        <p className="mb-0">Hobbies</p>

                                                        {this.state.profile.compte?.hobby?.map((hobby, i) => <p key={i} className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {hobby.en_US}
                                                        </p>)}
                                                    </div>

                                                    <div className="col-6">
                                                        <p className="mb-0">Sports</p>

                                                        {this.state.profile.compte?.sport?.map((sport, i) => <p key={i} className="text-dark fs-6 fw-bold mb-0 text-break">
                                                            {sport.en_US}
                                                        </p>)}
                                                    </div>

                                                    <div className="col-lg-2">
                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleAdditionalInformation()}>
                                                            Edit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="overview-box">
                                            <div className="title d-flex align-items-center justify-content-between">
                                                <h5>Account Settings</h5>

                                                <button className="p-2" style={{ cursor: 'pointer' }} type="button" data-target="#account-settings" onClick={toggleSection} title="Toggle display">
                                                    <i className="fa fa-chevron-up fa-lg text-dark" style={{ pointerEvents: 'none' }}></i>
                                                </button>
                                            </div>
                                            <div className="desc" id="account-settings">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleEmailAddress()}>
                                                            Change Email Address
                                                        </button>
                                                    </div>

                                                    <div className="col-6">
                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.togglePassword()}>
                                                            Change Password
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<div className="overview-box">*/}
                                        {/*    <div className="title">*/}
                                        {/*        <h5>Education Details</h5>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="desc">*/}
                                        {/*        <div className="candidate-timeline">*/}
                                        {/*            <div className="timeline-panel">*/}
                                        {/*                <div className="timeline-head">*/}
                                        {/*                    <h3>Senior Software Engineer</h3>*/}
                                        {/*                    <span className="timeline-year">2009-2012</span>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="timeline-body">*/}
                                        {/*                    <h5>Clothing Store</h5>*/}
                                        {/*                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                                        {/*                        incididunt.</p>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="timeline-panel">*/}
                                        {/*                <div className="timeline-head">*/}
                                        {/*                    <h3>Self Employe Professional</h3>*/}
                                        {/*                    <span className="timeline-year">2012-2015</span>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="timeline-body">*/}
                                        {/*                    <h5>Fashion Store</h5>*/}
                                        {/*                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                                        {/*                        incididunt.</p>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="timeline-panel">*/}
                                        {/*                <div className="timeline-head">*/}
                                        {/*                    <h3>Self Employe Professional</h3>*/}
                                        {/*                    <span className="timeline-year">2014-2015</span>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="timeline-body">*/}
                                        {/*                    <h5>Jewelry Store</h5>*/}
                                        {/*                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                                        {/*                        incididunt.</p>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {/*<div className="overview-box">*/}
                                        {/*    <div className="title">*/}
                                        {/*        <h5>Skills</h5>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="desc pb-30">*/}
                                        {/*        /!* ttm-progress-bar *!/*/}
                                        {/*        <div className="ttm-progress-bar clearfix">*/}
                                        {/*            <h3 className="progressbar-title">Recruitment Outsourcing</h3>*/}
                                        {/*            <ProgressBar  rect  percentage="90" />*/}
                                        {/*        </div>*/}
                                        {/*        /!* ttm-progress-bar end *!/*/}
                                        {/*        /!* ttm-progress-bar *!/*/}
                                        {/*        <div className="ttm-progress-bar clearfix">*/}
                                        {/*            <h3 className="progressbar-title">Contingency Recruitment</h3>*/}
                                        {/*            <ProgressBar  rect  percentage="70" />*/}
                                        {/*        </div>*/}
                                        {/*        /!* ttm-progress-bar end *!/*/}
                                        {/*        /!* ttm-progress-bar *!/*/}
                                        {/*        <div className="ttm-progress-bar clearfix">*/}
                                        {/*            <h3 className="progressbar-title">On-demand Recruitment</h3>*/}
                                        {/*            <ProgressBar  rect  percentage="80" />*/}
                                        {/*        </div>/!* ttm-progress-bar end *!/*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

                {/* action-section */}
                <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box icon-align-before-content style2">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                                <i className="flaticon flaticon-recruitment-5"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Incredible Recruitment & Staffing Agency</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end */}
                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                       href={process.env.PUBLIC_URL + '/'}>Hiring Now!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* action-section end */}

                <Footer/>

            </div>
        </>
    }
}
