import React, {useState, useEffect} from 'react'
import '../style.css'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../config";
import SweetAlert from 'sweetalert2-react'
import {fetchData, getAuth} from "../../../services";

export const ContactDetails = ({display, toggleDisplay, compte}) =>  {
    const [errors, setErrors] = useState([])
    const [profile, setProfile] = useState({})
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        setProfile({
            mobile_number: compte?.mobile_number,
            whatsapp_number: compte?.whatsapp_number,
            professional_number: compte?.professional_number,
            website_blog: compte?.website_blog,
            secondary_email: compte?.secondary_email,
        })
    }, [setProfile, compte])

    const handleSetMobileNumber = (e) => {
        setProfile({...profile, mobile_number: e.target.value})
    }

    const handleSetWhatsappNumber = (e) => {
        setProfile({...profile, whatsapp_number: e.target.value})
    }

    const handleSetProfessionalNumber = (e) => {
        setProfile({...profile, professional_number: e.target.value})
    }

    const handleSetWebsite = (e) => {
        setProfile({...profile, website_blog: e.target.value})
    }

    const handleSetSecondaryEmail = (e) => {
        setProfile({...profile, secondary_email: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData('contact_details', {
            method: 'put',
            body: JSON.stringify({
                mobile_number: profile.mobile_number,
                whatsapp_number: profile.whatsapp_number,
                professional_number: profile.professional_number,
                website_blog: profile.website_blog,
                secondary_email: profile.secondary_email,
                email: getAuth().account.email_address,
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/details`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Contact Details</h5>

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>Mobile Number <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-mobile-alt"></i>
                                    <input type="text" id="mobile_number" defaultValue={profile.mobile_number} onChange={handleSetMobileNumber} />
                                </label>

                                {errors.mobile_number && <span className="text-danger">
                                    {errors.mobile_number[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Whatsapp Number <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fab fa-whatsapp"></i>
                                    <input type="text" id="whatsapp_number" defaultValue={profile.whatsapp_number} onChange={handleSetWhatsappNumber} />
                                </label>

                                {errors.whatsapp_number && <span className="text-danger">
                                    {errors.whatsapp_number[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Professional Number</span>

                                <label className="mt-1">
                                    <i className="fa fa-mobile-alt"></i>
                                    <input type="text" id="professional_number" defaultValue={profile.professional_number} onChange={handleSetProfessionalNumber} />
                                </label>

                                {errors.professional_number && <span className="text-danger">
                                    {errors.professional_number[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Secondary Email</span>

                                <label className="mt-1">
                                    <i className="far fa-envelope"></i>
                                    <input type="email" id="secondary_email" defaultValue={profile.secondary_email} onChange={handleSetSecondaryEmail} />
                                </label>

                                {errors.secondary_email && <span className="text-danger">
                                    {errors.secondary_email[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Website</span>

                                <label className="mt-1">
                                    <i className="fa fa-link"></i>
                                    <input type="url" id="website_blog" defaultValue={profile.website_blog} onChange={handleSetWebsite} />
                                </label>

                                {errors.website_blog && <span className="text-danger">
                                    {errors.website_blog[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6"></div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
