import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";
import moment from "moment";

export const ExperienceShow = ({experienceId, display, toggleDisplay}) => {
    const [experience, setExperience] = useState({})

    useEffect(() => {
        fetchData(`experience/${experienceId}`)
            .then(data => setExperience(data.data))
    }, [setExperience, experienceId])

    return <div className={"modal-container " + (display ? 'show' : '')}>
        <div className="modal-content" style={{ marginInline: '30em' }}>
            <div className="modal-header">
                <h5>Experience : {experience?.job_title}</h5>

                <button type="button" onClick={() => toggleDisplay()} title="Close">
                    <i className="fa fa-times fa-lg"></i>
                </button>
            </div>

            <div className="modal-body">
                <div className="row gy-3">
                    <div className="col-lg-4">
                        <p className="mb-0">Job Type</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.job_type?.en_US ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-4">
                        <p className="mb-0">Type Of Company</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.type_of_business?.en_US ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Number Of Employees</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.number_of_employees?.en_US ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Sector</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.sector?.en_US ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Salary Range</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.salary_range?.en_US ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">State City</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.State_city?.en_US ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Other State City</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.other_state_city ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Assignment Location</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.assignment_location ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Job Title</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.job_title ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Name Of Employee</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.name_of_employee ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Current Job</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {parseInt(experience?.current_job) === 1 ? 'Yes' : 'No'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Employee Phone Number</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.employee_phone_number ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Employee Website</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.employee_website_blog ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Beginning Date</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {moment(experience?.beginning_date).format('LL') ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Ending Date</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {moment(experience?.ending_date).format('LL') ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Description Of Duties And Responsabilities</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.description_of_duties_and_responsabilities ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-4">
                        <p className="mb-0">Key Achievement</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.key_achievements ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-4">
                        <p className="mb-0">Reason For Leaving The Post</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {experience?.reasons_for_leaving_the_post ?? '-'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
