import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";
import moment from "moment/moment";

export const EducationShow = ({educationId, display, toggleDisplay}) => {
    const [education, setEducation] = useState({})

    useEffect(() => {
        fetchData(`educations/${educationId}`)
            .then(data => setEducation(data.data))
    }, [setEducation, educationId])

    return <div className={"modal-container " + (display ? 'show' : '')}>
        <div className="modal-content">
            <div className="modal-header">
                <h5>Education : {education?.degree?.en_US}</h5>

                <button type="button" onClick={() => toggleDisplay()} title="Close">
                    <i className="fa fa-times fa-lg"></i>
                </button>
            </div>

            <div className="modal-body">
                <div className="row gy-3">
                    <div className="col-lg-6">
                        <p className="mb-0">Degree</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {education?.degree?.en_US ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Course Of Study</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {education?.course_of_study?.en_US ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Others Course Of Study</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {education?.others_course_of_study ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Beginning Date</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {moment(education?.beginning_date).format('LL') ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <p className="mb-0">Ending Date</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {moment(education?.ending_date).format('LL') ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <p className="mb-0">State City</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {education?.state_city?.en_US ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <p className="mb-0">Other City</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {education?.other_city ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Institution</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {education?.institution ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Degree Obtained</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {parseInt(education?.degree_obtained) === 1 ? 'Yes' : 'No'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Short Description</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {education?.short_description ?? '-'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
