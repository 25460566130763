import React, { Component } from 'react';
import Header from '../../components/layout/Header';
import PageHeader from "../../components/layout/PageHeader";
import { Footer } from '../../components/layout/Footer';
import {fetchData, isAuth} from "../../services";
import moment from "moment/moment";
import Parser from 'html-react-parser';
import {RegisterApplication} from "../../components/modal";

export class JobDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            job: {},
            displayRegisterApplication: false,
        }

        this.toggleRegisterApplication = this.toggleRegisterApplication.bind(this)
    }

    componentDidMount() {
        fetchData('job_postings/' + this.props.slug, {}, false)
            .then(data => {
                this.setState({
                    job: data.data,
                })
            })
    }

    toggleRegisterApplication() {
        this.setState({displayRegisterApplication: !this.state.displayRegisterApplication})
    }

    render() {
        return <>
            {this.state.displayRegisterApplication && <RegisterApplication
                slug={this.state.job.slug}
                id={this.state.job.id}
                display={this.state.displayRegisterApplication}
                toggleDisplay={this.toggleRegisterApplication}
            />}

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Job Details"
                    breadcrumb="Job"
                />
                {/* PageHeader end */}


                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                <div className="job_list-widget">
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title"><i className="ti ti-files"></i>Job Informations</h3>
                                        <ul>
                                            <li className="d-flex flex-column">
                                                <b className="mr-5">Job Type:</b>
                                                <span className="text-dark fw-bold text-break">
                                                    {this.state.job.job_type?.en_US ?? '-'}
                                                </span>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <b className="mr-5">Location:</b>
                                                <span className="text-dark fw-bold text-break">
                                                    {this.state.job.city?.en_US ?? '-'}
                                                </span>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <b className="mr-5">Offered Salary:</b>
                                                <span className="text-dark fw-bold text-break">
                                                    {this.state.job.salary_expectation?.en_US ?? '-'}
                                                </span>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <b className="mr-5">Posted:</b>
                                                <span className="text-dark fw-bold text-break">
                                                    {moment(this.state.job.publication_date ?? '-').fromNow()}
                                                </span>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <b className="mr-5">Experience:</b>
                                                <span className="text-dark fw-bold text-break">
                                                    {this.state.job.experience?.en_US ?? '-'}
                                                </span>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <b className="mr-5">Industry:</b>
                                                <span className="text-dark fw-bold text-break">
                                                    {this.state.job.sector?.en_US ?? '-'}
                                                </span>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <b className="mr-5">Career Level:</b>
                                                <span className="text-dark fw-bold text-break">
                                                    {this.state.job.function?.en_US ?? '-'}
                                                </span>
                                            </li>
                                            <li className="d-flex flex-column">
                                                <b className="mr-5">Available Places:</b>
                                                <span className="text-dark fw-bold text-break">
                                                    {parseInt(this.state.job.number_of_available_places)}
                                                </span>
                                            </li>
                                        </ul>
                                    </aside>
                                    <aside className="widget location-widget p-0">
                                        <iframe width="100%" height="350" src={'https://maps.google.com/maps?q=' + this.state.job.city?.en_US + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe>

                                        <div className="p-20">
                                            <div>
                                                <span className="text-theme-SkinColor">
                                                <i className="fa fa-map-marker-alt mr-10"></i></span> {this.state.job.city?.en_US}
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                            <div className="col-lg-8 content-area">
                                <div className="d-flex justify-content-end mb-3">
                                    <button className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => document.location.href = process.env.PUBLIC_URL + '/job-list'}>
                                        <i className="fa fa-arrow-left"></i>
                                        <span className="ms-3">Back to Job List</span>
                                    </button>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12">

                                        {/* featured-imagebox */}
                                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3>{this.state.job.available_post}</h3>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>Published {moment(this.state.job.publication_date).fromNow()}</p>
                                                </div>
                                                <div className="featured-bottom">
                                                    <div className="job-meta d-flex align-items-center gap-3">
                                                        <p className="mb-0"><i className="fa fa-map-marker-alt me-2" style={{ color: '#ff8d00'}}></i>{this.state.job.city?.en_US}</p>
                                                        <p className="mb-0"><i className="fa fa-user-tie me-2" style={{ color: '#ff8d00'}}></i>{this.state.job.job_type?.en_US}</p>
                                                        {this.state.job.salary_expectation &&
                                                            <p className="mb-0"><i className="fa fa-money-bill me-2" style={{ color: '#ff8d00'}}></i>{this.state.job.salary_expectation?.en_US}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>Job Description</h5>
                                            </div>
                                            <div className="desc">
                                                {this.state.job?.comment || '-'}
                                            </div>
                                        </div>
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>Responsibilities</h5>
                                            </div>
                                            <div className="desc">
                                                {Parser(`${this.state.job?.mission}`)}
                                            </div>
                                        </div>
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>Qualifications & Skills</h5>
                                            </div>
                                            <div className="desc">
                                                {Parser(`${this.state.job?.required_profile}`)}
                                            </div>
                                        </div>

                                        {isAuth() && <div className="d-flex justify-content-center mt-3">
                                            <button className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => this.toggleRegisterApplication()}>
                                                Register application
                                            </button>
                                        </div>}

                                        <div className="overview-box mt-3">
                                            <div className="title">
                                                <div className="d-sm-flex justify-content-between mb_10">
                                                    <h5 className="mb-10">Share Job :</h5>
                                                    <div className="social-icons circle mb-10">
                                                        <ul className="list-inline">
                                                            <li><a href="src/pages/Job/JobDetails#facebook" rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                                            <li><a href="src/pages/Job/JobDetails#twitter" rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>
                                                            <li><a href="src/pages/Job/JobDetails#twitter" rel="noopener" aria-label="twitter"><i className="ti ti-linkedin"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>


                {/* action-section */}
                <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box icon-align-before-content style2">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                                <i className="flaticon flaticon-recruitment-5"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Incredible Recruitment & Staffing Agency</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end */}
                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                    href={process.env.PUBLIC_URL + '/'}>Hiring Now!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* action-section end */}

                
            <Footer/>
                        
            </div>
        </>
    }
}

export default JobDetails;