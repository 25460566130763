import React, {useState} from 'react'
import '../style.css'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../config";
import SweetAlert from 'sweetalert2-react'
import {fetchData, getAuth} from "../../../services";

export const PasswordConfirmation = ({display, toggleDisplay, profile, setErrors}) =>  {
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    const handleSetPassword = (e) => {
        setPassword(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData('security_setting', {
            method: 'put',
            body: JSON.stringify({
                current_password: password,
                current_email: getAuth().account.email_address,
                new_email: profile.new_email,
                email_confirmation: profile.email_confirmation,
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === 'Your Current password is incorrect') {
                    return setErrorMessage(data.message)
                }

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                    return toggleDisplay(false)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/details`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')} style={{ zIndex: '1111'}}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Password Confirmation</h5>

                    <button type="button" onClick={() => toggleDisplay(!display)} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-12">
                                <span className="fs-6">You need to provide your password to complete this action</span>

                                <label className="mt-1">
                                    <i className="ti ti-lock"></i>
                                    <input type="password" id="password" defaultValue={password}  onChange={handleSetPassword} />
                                </label>

                                {errorMessage !== '' && <span className="text-danger">
                                    {errorMessage}
                                </span>}
                            </div>

                            <div className="col-lg-12 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save Changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>

}
