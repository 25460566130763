//https://www.w3schools.com/js/js_cookies.asp

export const setCookie = (name, value, rememberMe = null) => {
    const d = new Date()

    if (!rememberMe) {
        d.setTime(d.getTime() + (3600 * 1000))
    } else {
        d.setTime(d.getTime() + (3600 * 24 * 365 * 1000))
    }

    let expires = "expires=" + d.toUTCString()

    document.cookie = name + "=" + value + ";" + expires + ";path=/"
}

export const getCookie = (name) => {
    name = name + "=";
    let ca = document.cookie.split(';')

    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }

    return "";
}

export const deleteCookie = (name) => {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export const checkCookie = (name) => {
    return getCookie(name) !== ''
}
