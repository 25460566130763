import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";
import SweetAlert from 'sweetalert2-react'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../../config";
import moment from "moment";
import {toBase64} from "../../../../utils";

export const TrainingEdit = ({trainingId, display, toggleDisplay}) => {
    const url = trainingId === null ? 'training' : `training/${trainingId}`
    const method = trainingId === null ? 'post' : 'put'

    const [training, setTraining] = useState({})
    const [domains, setDomains] = useState([])
    const [errors, setErrors] = useState([])
    const [dateCompleted, setDateCompleted] = useState('')
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        if (trainingId !== null) {
            fetchData(`training/${trainingId}`)
                .then(data => {
                    setTraining(data.data)
                    setDateCompleted(moment(data.data.date_completed).format('YYYY-MM-DD'))
                })
        }
    }, [setTraining, trainingId])

    useEffect(() => {
        fetchData('training_domains')
            .then(data => setDomains(data.data))
    }, [setDomains])

    const handleSetTrainingDomain = (e) => {
        setTraining({...training, domain: parseInt(e.target.value)})
    }

    const handleSetOtherDomain = (e) => {
        setTraining({...training, other_domain: e.target.value})
    }

    const handleSetSpecificTraining = (e) => {
        setTraining({...training, specific_training: e.target.value})
    }

    const handleSetAttestationDocument = async (e) => {
        const data = await toBase64(e.target.files[0])
        setTraining({...training, attestation_document: data})
    }

    const handleSetDateCompleted = (e) => {
        setTraining({...training, date_completed: e.target.value})
    }

    const handleSetShortDescription = (e) => {
        setTraining({...training, short_description: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData(url, {
            method: method,
            body: JSON.stringify({
                domain: isNaN(training.domain) ? training.domain?.id_training_domain : training.domain,
                other_domain: training.other_domain,
                specific_training: training.specific_training,
                attestation_document: training.attestation_document,
                date_completed: moment(training.date_completed).format('DD/MM/YYYY'),
                short_description: training.short_description,
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/job-profile`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    {trainingId !== null && <h5>Training : {training?.domain?.en_US}</h5>}
                    {trainingId === null && <h5>Add Training</h5>}

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>Domain <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-certificate"></i>
                                    <select style={{ paddingLeft: '2em' }} id="domain" value={training?.domain?.id_training_domain} onChange={handleSetTrainingDomain}>
                                        <option value="">Select domain</option>

                                        {domains.map(domain => {
                                            return <option key={domain.id_training_domain} value={domain.id_training_domain} defaultChecked={training?.domain?.id_training_domain === domain.id_training_domain}>
                                                {domain.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.domain && <span className="text-danger">
                                    {errors.domain[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Other Domain</span>

                                <label className="mt-1">
                                    <i className="fa fa-certificate"></i>
                                    <input type="text" id="other_domain" defaultValue={training?.other_domain ?? ''} onChange={handleSetOtherDomain} />
                                </label>

                                {errors.other_domain && <span className="text-danger">
                                    {errors.other_domain[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Specific Training <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-certificate"></i>
                                    <input type="text" id="specific_training" defaultValue={training?.specific_training ?? ''} onChange={handleSetSpecificTraining} />
                                </label>

                                {errors.specific_training && <span className="text-danger">
                                    {errors.specific_training[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Attestation Document</span>

                                <label className="mt-1">
                                    <i className="fa fa-file-download"></i>
                                    <input type="file" id="attestation_document" onChange={handleSetAttestationDocument} />
                                </label>

                                {errors.attestation_document && <span className="text-danger">
                                    {errors.attestation_document[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Date Completed<span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-calendar"></i>
                                    <input type="date" id="date_completed" defaultValue={dateCompleted ?? ''} onChange={handleSetDateCompleted} />
                                </label>

                                {errors.date_completed && <span className="text-danger">
                                    {errors.date_completed[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Short Description</span>

                                <label className="mt-1">
                                    <i className="fa fa-pen"></i>
                                    <input type="text" id="short_description" defaultValue={training?.short_description ?? ''} onChange={handleSetShortDescription} />
                                </label>

                                {errors.short_description && <span className="text-danger">
                                    {errors.short_description[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
