import {fetchData} from "../fetch-data";
import {
    checkCookie,
    deleteCookie,
    getCookie,
    setCookie
} from "./cookies";

export const isAuth = () => {
    return checkCookie('auth')
}

export const getAuth = () => {
    if (!isAuth()) {
        document.location.href = process.env.PUBLIC_URL + '/login'
    }

    return JSON.parse(getCookie('auth'))
}

export const setAuth = (auth, rememberMe = null) => {
    setCookie('auth', JSON.stringify(auth), rememberMe)
    setCookie('rememberMe', rememberMe ? 'yes' : 'no')
}

export const rememberMe = () => {
    return getCookie('name') === 'yes'
}

export const forgetAuth = () => {
    deleteCookie('auth')
    deleteCookie('rememberMe')

    fetchData('logout')
        .then(() => document.location.href = process.env.PUBLIC_URL + '/')
}
