import React, {useState, useEffect} from 'react'
import '../style.css'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../config";
import SweetAlert from 'sweetalert2-react'
import {fetchData} from "../../../services";

export const AddressAndLocation = ({display, toggleDisplay, compte}) =>  {
    const [errors, setErrors] = useState([])
    const [profile, setProfile] = useState({})
    const [cities, setCities] = useState([])
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        setProfile({
            state: compte?.address?.address_city?.id_city,
            other_state: compte?.address?.other_city_address,
            street_address: compte?.address?.street_address,
            zip_postal_code: compte?.address?.ZIP_code,
        })
    }, [setProfile, compte])

    useEffect(() => {
        fetchData('cities')
            .then(data => setCities(data.data))
    }, [setCities])

    const handleSetState = (e) => {
        setProfile({...profile, state: e.target.value})
    }

    const handleSetOtherState = (e) => {
        setProfile({...profile, other_state: e.target.value})
    }

    const handleSetAddress = (e) => {
        setProfile({...profile, street_address: e.target.value})
    }

    const handleSetZipPostalCode = (e) => {
        setProfile({...profile, zip_postal_code: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData('address_location', {
            method: 'put',
            body: JSON.stringify({
                state: profile.state,
                other_state: profile.other_state,
                street_address: profile.street_address,
                zip_postal_code: profile.zip_postal_code,
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/details`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Address & Location</h5>

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>State <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-flag"></i>
                                    <select style={{ paddingLeft: '2em' }} id="state" value={profile.state} onChange={handleSetState}>
                                        <option defaultValue="">Select state</option>

                                        {cities.map(city => {
                                            return <option key={city.id_city} value={city.id_city} defaultChecked={profile.state === city.id_city}>
                                                {city.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.state && <span className="text-danger">
                                    {errors.state[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Other State</span>

                                <label className="mt-1">
                                    <i className="fa fa-flag"></i>
                                    <input type="text" id="other_state" defaultValue={profile.other_state} onChange={handleSetOtherState} />
                                </label>

                                {errors.other_state && <span className="text-danger">
                                    {errors.other_state[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Street Address <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-map-marker-alt"></i>
                                    <input type="text" id="street_address" defaultValue={profile.street_address} onChange={handleSetAddress} />
                                </label>

                                {errors.street_address && <span className="text-danger">
                                    {errors.street_address[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>ZIP Postal Code</span>

                                <label className="mt-1">
                                    <i className="fa fa-map-pin"></i>
                                    <input type="text" id="zip_postal_code" defaultValue={profile.zip_postal_code} onChange={handleSetZipPostalCode} />
                                </label>

                                {errors.zip_postal_code && <span className="text-danger">
                                    {errors.zip_postal_code[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6"></div>

                            <div className="col-lg-6"></div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
