import React, {useState, useEffect} from 'react'
import '../style.css'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../config";
import SweetAlert from 'sweetalert2-react'
import {fetchData} from "../../../services";

export const SocialNetwork = ({display, toggleDisplay, compte}) =>  {
    const [errors, setErrors] = useState([])
    const [networks, setNetworks] = useState([])
    const [options, setOptions] = useState([])
    const [socialNetwork, setSocialNetwork] = useState('')
    const [socialNetworkLink, setSocialNetworkLink] = useState('')
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        let _networks = []

        compte?.network?.forEach(
            _network => _networks.push({
                name: _network.social_network.name,
                id: _network.social_network.id_network,
                link: _network.account_url_or_username
            })
        )

        setNetworks(_networks)
    }, [setNetworks, compte])

    useEffect(() => {
        fetchData('networks')
            .then(data => {
                let options = data.data.map(function (option) {
                    return {
                        name: option.name,
                        id: option.id_network,
                        link: option.account_url_or_username
                    }
                })

                setOptions(options)
            })
    } , [setOptions])

    const handleSetSocialNetwork = (e) => {
        setSocialNetwork(e.target.value)
    }

    const handleSetSocialNetworkLink = (e) => {
        setSocialNetworkLink(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i>'

        fetchData('social_network', {
            method: 'put',
            body: JSON.stringify({
                social_network: parseInt(socialNetwork),
                link: socialNetworkLink
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/details`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Social Network</h5>

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-4">
                            <div className="col-lg-12">
                                <table className="table table-sm">
                                    <thead>
                                        <tr>
                                            <th className="fw-bold">Social Network</th>
                                            <th className="fw-bold">Profile Link</th>
                                            {/*<th></th>*/}
                                        </tr>
                                    </thead>

                                    <tbody className="table-group-divider">
                                        {networks.map((network, i) => <tr key={i}>
                                            <td>{network.name}</td>
                                            <td>{network.link}</td>

                                            {/*<td align="center">*/}
                                            {/*    <button style={{ color: '#FF8D00FF', cursor: 'pointer' }} title="Remove">*/}
                                            {/*        <i className="fa fa-times fa-lg"></i>*/}
                                            {/*    </button>*/}
                                            {/*</td>*/}
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-5">
                                        <label>
                                            <i className="fa fa-users"></i>
                                            <select id="social_network" value={socialNetwork} onChange={handleSetSocialNetwork} style={{ paddingLeft: '2em' }}>
                                                <option>Select a social network</option>

                                                {options.map(function (social_network) {
                                                    return <option key={social_network.id} value={social_network.id}>
                                                        {social_network.name}
                                                    </option>
                                                })}
                                            </select>
                                        </label>

                                        {errors.social_network && <span className="text-danger">
                                            {errors.social_network[0]}
                                        </span>}
                                    </div>

                                    <div className="col-5">
                                        <label>
                                            <i className="fa fa-link"></i>
                                            <input type="url" id="link" defaultValue={socialNetworkLink} onChange={handleSetSocialNetworkLink} placeholder="https://" />
                                        </label>

                                        {errors.link && <span className="text-danger">
                                            {errors.link[0]}
                                        </span>}
                                    </div>

                                    <div className="col-2">
                                        <button className="px-0 submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                type="submit" onClick={handleSubmit}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
