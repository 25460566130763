import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";

export const LanguageSkillShow = ({languageSkillId, display, toggleDisplay}) => {
    const [languageSkill, setLanguageSkill] = useState({})

    useEffect(() => {
        fetchData(`language_skills/${languageSkillId}`)
            .then(data => setLanguageSkill(data.data))
    }, [setLanguageSkill, languageSkillId])

    return <div className={"modal-container " + (display ? 'show' : '')}>
        <div className="modal-content">
            <div className="modal-header">
                <h5>Language Skill : {languageSkill?.language?.en_US}</h5>

                <button type="button" onClick={() => toggleDisplay()} title="Close">
                    <i className="fa fa-times fa-lg"></i>
                </button>
            </div>

            <div className="modal-body">
                <div className="row gy-3">
                    <div className="col-lg-6">
                        <p className="mb-0">Language</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {languageSkill?.language?.en_US ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Language Proficiency</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {languageSkill?.level?.en_US ?? '-'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
