import React, { Component } from 'react';
import Header from '../../components/layout/Header';
import PageHeader from "../../components/layout/PageHeader";
import { Footer } from '../../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {API_URL, HTTP_HEADERS, INVALID_DATA_RESPONSE_MESSAGE} from "../../config";
import SweetAlert from 'sweetalert2-react'
import {setAuth} from "../../services";

export default class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
            rememberMe: false,
            errors: [],
            alert: {
                type: '',
                text: '',
                show: false,
                redirect: ''
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSetEmail = this.handleSetEmail.bind(this)
        this.handleSetPassword = this.handleSetPassword.bind(this)
        this.handleSetRememberMe = this.handleSetRememberMe.bind(this)
    }

    handleSetEmail(e) {
        this.setState({email: e.target.value})
    }
    
    handleSetPassword(e) {
        this.setState({password: e.target.value})
    }

    handleSetRememberMe(e) {
        this.setState({rememberMe: e.target.checked})
    }

    handleSubmit(e) {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Sign in'

        fetch(`${API_URL}/login`, {
            method: 'post',
            headers: HTTP_HEADERS(),
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password,
            }),
        })
            .then(res => res.json())
            .then(data => {
                e.target.innerHTML = 'Sign in'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    if (!data.errors.email?.includes("These credentials do not match our records.")) {
                        this.setState({errors: data.errors})
                    } else {
                        this.setState({
                            alert: {
                                type: 'error',
                                text: data.errors.email,
                                show: true,
                                redirect: `${process.env.PUBLIC_URL}/login`
                            }
                        })
                    }
                } else {
                    setAuth(data, this.state.rememberMe)

                    this.setState({
                        alert: {
                            type: 'success',
                            text: "You've signed in successfully!",
                            show: true,
                            redirect: `${process.env.PUBLIC_URL}/candidate/details`
                        }
                    })
                }
            })
    }
    
    render() {
        return <>
            <SweetAlert
                show={this.state.alert.show}
                text={this.state.alert.text}
                type={this.state.alert.type}
                onConfirm={() => document.location.href = this.state.alert.redirect}
            />

            <div className="site-main">
                <Header/>

                {/* PageHeader */}
                <PageHeader
                    title="Login"
                    breadcrumb="Login"
                />
                {/* PageHeader end */}

                {/* login */}
                <div className="ttm-row login-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <div className="text-center mb-20">
                                            <h3>Login To Your Account</h3>
                                        </div>
                                        <div className="ttm-tabs ttm-tab-style-02">
                                            <Tabs>
                                                <TabList className="tabs">
                                                    <Tab className="tab">
                                                        <a href="#">
                                                            <i className="flaticon flaticon-research"></i>
                                                            <span>Candidate</span><h5>Login as a Candidate</h5>
                                                        </a>
                                                    </Tab>
                                                    <Tab className="tab">
                                                        <a href="#">
                                                            <i className="flaticon flaticon-job-search"></i>
                                                            <span>Employer</span><h5>Login as a Employer</h5>
                                                        </a>
                                                    </Tab>
                                                </TabList>
                                                <div className="content-tab">
                                                    <TabPanel>
                                                        <form id="login_form" className="login_form wrap-form">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <label>
                                                                        <i className="ti ti-email"></i>
                                                                        <input type="email" id="email" placeholder="Email Address" value={this.state.email} onChange={this.handleSetEmail} />
                                                                    </label>

                                                                    {this.state.errors.email && <span className="text-danger">
                                                                        {this.state.errors.email[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label>
                                                                        <i className="ti ti-lock"></i>
                                                                        <input type="password" id="password" placeholder="Password" value={this.state.password} onChange={this.handleSetPassword} />
                                                                    </label>

                                                                    {this.state.errors.password && <span className="text-danger">
                                                                        {this.state.errors.password[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label>
                                                                        <div className="forgot-pwd text-center mt-5">
                                                                            <p><a href={process.env.PUBLIC_URL + '/reset-password'} className="text-theme-SkinColor">Forgot Password?</a></p>
                                                                            <p>Don't have account? <a href={process.env.PUBLIC_URL + '/register'} className="text-theme-SkinColor">Sign Up here</a></p>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 mx-auto">
                                                                    <label className="mb-0">
                                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="submit" onClick={this.handleSubmit}>
                                                                            Sign in
                                                                        </button>
                                                                    </label>
                                                                </div>
                                                                {/*<div className="col-lg-12 mx-auto">*/}
                                                                {/*    <label>*/}
                                                                {/*        <div className="forgot-pwd text-center mt-5">*/}
                                                                {/*            <input className="w-auto mr-10" id="remember" type="checkbox" value="yes" onClick={this.handleSetRememberMe} />*/}
                                                                {/*            <span>Remember Password</span>*/}
                                                                {/*        </div>*/}
                                                                {/*    </label>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </form>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <form id="login_form" className="login_form wrap-form">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <label>
                                                                        <i className="ti ti-email"></i>
                                                                        <input type="email" id="txtemail" placeholder="Email Address" />
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label>
                                                                        <i className="ti ti-lock"></i>
                                                                        <input type="password" id="password" placeholder="Password"/>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label>
                                                                        <div className="forgot-pwd text-center mt-5">
                                                                            <p><a href="src/pages/Authentication/Login#" className="text-theme-SkinColor">Forgot Password?</a></p>
                                                                            <input className="w-auto mr-10" id="remember" type="checkbox" value="yes" />
                                                                            <span>Remember Password</span>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 mx-auto">
                                                                    <label className="mb-0">
                                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                                                type="submit">Sign up</button>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </TabPanel>
                                                </div>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* login end */}

                <Footer/>
            </div>
        </>
    }
}