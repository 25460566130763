import React, {useState, useEffect} from 'react'
import '../style.css'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../config";
import SweetAlert from 'sweetalert2-react'
import {fetchData} from "../../../services";

export const FurtherInformation = ({display, toggleDisplay, compte}) =>  {
    const [errors, setErrors] = useState([])
    const [profile, setProfile] = useState({})
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        setProfile({
            further_information: compte?.further_information,
        })
    }, [setProfile, compte])

    const handleSetFurtherInformation = (e) => {
        setProfile({...profile, further_information: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData('further_information', {
            method: 'put',
            body: JSON.stringify({
                further_information: profile.further_information,
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/details`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Further Information</h5>

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-12">
                                <textarea id="further_information" className="w-100" rows="5" onChange={handleSetFurtherInformation} defaultValue={profile.further_information}></textarea>

                                {errors.further_information && <span className="text-danger">
                                    {errors.further_information[0]}
                                </span>}
                            </div>

                            <div className="col-lg-12 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
