import React, {useState, useEffect} from 'react'
import '../style.css'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../config";
import SweetAlert from 'sweetalert2-react'
import { MultiSelect } from "react-multi-select-component"
import {fetchData} from "../../../services";

export const JobInterest = ({display, toggleDisplay, compte}) =>  {
    const [errors, setErrors] = useState([])
    const [jobLevels, setJobLevels] = useState([])
    const [fonctions, setFonctions] = useState([])
    const [sectors, setSectors] = useState([])
    const [jobTypes, setJobTypes] = useState([])
    const [advanceNotice, setAdvanceNotice] = useState('')
    const [salaryExpectation, setSalaryExpectation] = useState('')
    const [mobilities, setMobilities] = useState([])
    const [jobLevelsOptions, setJobLevelsOptions] = useState([])
    const [fonctionsOptions, setFonctionsOptions] = useState([])
    const [sectorsOptions, setSectorsOptions] = useState([])
    const [jobTypesOptions, setJobTypesOptions] = useState([])
    const [mobilitiesOptions, setMobilitiesOptions] = useState([])
    const [disponibilities, setDisponibilities] = useState([])
    const [salaryExpectations, setSalaryExpectations] = useState([])
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        let _jobLevels = []
        let _fonctions = []
        let _sectors = []
        let _jobTypes = []
        let _mobilities = []

        compte?.job_level?.forEach(_job_level => _jobLevels.push({label: _job_level.en_US, value: _job_level.id_statut}))
        compte?.fonction?.forEach(_fonction => _fonctions.push({label: _fonction.en_US, value: _fonction.id_function}))
        compte?.sector?.forEach(_sector => _sectors.push({label: _sector.en_US, value: _sector.id_sector}))
        compte?.job_type?.forEach(_job_type => _jobTypes.push({label: _job_type.en_US, value: _job_type.id_statut}))
        compte?.mobilities?.forEach(_mobility => _mobilities.push({label: _mobility.en_US, value: _mobility.id_mobility}))

        setJobLevels(_jobLevels)
        setFonctions(_fonctions)
        setSectors(_sectors)
        setJobTypes(_jobTypes)
        setMobilities(_mobilities)
        setAdvanceNotice(compte?.advance_notice?.id_disponibility)
        setSalaryExpectation(compte?.salary_expectation?.id_salary_expectation)
    }, [
        setJobLevels,
        setFonctions,
        setSectors,
        setJobTypes,
        setAdvanceNotice,
        setMobilities,
        setSalaryExpectation,
        compte
    ])

    useEffect(() => {
        fetchData('job_levels')
            .then(data => {
                let options = data.data.map(function (option) {
                    return {
                        label: option.en_US,
                        value: option.id_statut
                    }
                })

                setJobLevelsOptions(options)
            })
    } , [setJobLevelsOptions])

    useEffect(() => {
        fetchData('functions')
            .then(data => {
                let options = data.data.map(function (option) {
                    return {
                        label: option.en_US,
                        value: option.id_function
                    }
                })

                setFonctionsOptions(options)
            })
    } , [setFonctionsOptions])

    useEffect(() => {
        fetchData('sectors')
            .then(data => {
                let options = data.data.map(function (option) {
                    return {
                        label: option.en_US,
                        value: option.id_sector
                    }
                })

                setSectorsOptions(options)
            })
    } , [setSectorsOptions])

    useEffect(() => {
        fetchData('type_of_contract')
            .then(data => {
                let options = data.data.map(function (option) {
                    return {
                        label: option.en_US,
                        value: option.id_job_type
                    }
                })

                setJobTypesOptions(options)
            })
    } , [setJobTypesOptions])

    useEffect(() => {
        fetchData('disponibilities')
            .then(data => setDisponibilities(data.data))
    } , [setDisponibilities])

    useEffect(() => {
        fetchData('salary_expectations')
            .then(data => setSalaryExpectations(data.data))
    } , [setSalaryExpectations])

    useEffect(() => {
        fetchData('mobilities')
            .then(data => {
                let options = data.data.map(function (option) {
                    return {
                        label: option.en_US,
                        value: option.id_mobility
                    }
                })

                setMobilitiesOptions(options)
            })
    } , [setMobilitiesOptions])

    const handleSetAdvanceNotice = (e) => {
        setAdvanceNotice(e.target.value)
    }

    const handleSetSalaryExpectation = (e) => {
        setSalaryExpectation(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData('job_interest', {
            method: 'put',
            body: JSON.stringify({
                job_level: jobLevels.map(_job_level => _job_level.value),
                functions: fonctions.map(_fonction => _fonction.value),
                sectors: sectors.map(_sector => _sector.value),
                job_type: jobTypes.map(_job_type => _job_type.value),
                advance_notice: parseInt(advanceNotice),
                salary_expectation: parseInt(salaryExpectation),
                mobilities: mobilities.map(_mobility => _mobility.value)
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/details`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Job Interest</h5>

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>Job Levels</span>

                                <div className="mt-1 multiselect">
                                    <i className="fa fa-user-tie"></i>
                                    <MultiSelect options={jobLevelsOptions} value={jobLevels} onChange={setJobLevels} labelledBy="Select" />
                                </div>

                                {errors.job_level && <span className="text-danger">
                                    {errors.job_level[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Fonctions</span>

                                <div className="mt-1 multiselect">
                                    <i className="fa fa-user-tie"></i>
                                    <MultiSelect options={fonctionsOptions} value={fonctions} onChange={setFonctions} labelledBy="Select" />
                                </div>

                                {errors.fonction && <span className="text-danger">
                                    {errors.fonction[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Sectors</span>

                                <div className="mt-1 multiselect">
                                    <i className="fa fa-user-tie"></i>
                                    <MultiSelect options={sectorsOptions} value={sectors} onChange={setSectors} labelledBy="Select" />
                                </div>

                                {errors.sectors && <span className="text-danger">
                                    {errors.sectors[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Job Types</span>

                                <div className="mt-1 multiselect">
                                    <i className="fa fa-user-tie"></i>
                                    <MultiSelect options={jobTypesOptions} value={jobTypes} onChange={setJobTypes} labelledBy="Select" />
                                </div>

                                {errors.job_type && <span className="text-danger">
                                    {errors.job_type[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Advance Notice</span>

                                <label className="mt-1">
                                    <i className="fa fa-user-check"></i>
                                    <select style={{ paddingLeft: '2em' }} id="advance_notice" value={advanceNotice} onChange={handleSetAdvanceNotice}>
                                        <option defaultValue="">Select advance notice</option>

                                        {disponibilities.map(disponibility => {
                                            return <option key={disponibility.id_disponibility} value={disponibility.id_disponibility} defaultChecked={advanceNotice === disponibility.id_disponibility}>
                                                {disponibility.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.advance_notice && <span className="text-danger">
                                    {errors.advance_notice[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Mobilities</span>

                                <div className="mt-1 multiselect">
                                    <i className="fa fa-map"></i>
                                    <MultiSelect options={mobilitiesOptions} value={mobilities} onChange={setMobilities} labelledBy="Select" />
                                </div>

                                {errors.mobilities && <span className="text-danger">
                                    {errors.mobilities[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Salary Expectation</span>

                                <label className="mt-1">
                                    <i className="fa fa-dollar-sign"></i>
                                    <select style={{ paddingLeft: '2em' }} id="salary_expectation" value={salaryExpectation} onChange={handleSetSalaryExpectation}>
                                        <option defaultValue="">Select salary expectation</option>

                                        {salaryExpectations.map(salary => {
                                            return <option key={salary.id_salary_expectation} value={salary.id_salary_expectation} defaultChecked={salaryExpectation === salary.id_salary_expectation}>
                                                {salary.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.salary_expectation && <span className="text-danger">
                                    {errors.salary_expectation[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6"></div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
