import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";
import SweetAlert from 'sweetalert2-react'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../../config";
import moment from "moment";

export const CertificationEdit = ({certificationId, display, toggleDisplay}) => {
    const url = certificationId === null ? 'certifications' : `certifications/${certificationId}`
    const method = certificationId === null ? 'post' : 'put'

    const [certification, setCertification] = useState({})
    const [domains, setDomains] = useState([])
    const [errors, setErrors] = useState([])
    const [beginningDate, setBeginningDate] = useState('')
    const [endingDate, setEndingDate] = useState('')
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        if (certificationId !== null) {
            fetchData(`certifications/${certificationId}`)
                .then(data => {
                    setCertification(data.data)
                    setBeginningDate(moment(data.data.beginning_date).format('YYYY-MM-DD'))
                    setEndingDate(moment(data.data.ending_date).format('YYYY-MM-DD'))
                })
        }
    }, [setCertification, certificationId])

    useEffect(() => {
        fetchData('professional_domains')
            .then(data => setDomains(data.data))
    }, [setDomains])

    const handleSetProfessionalDomain = (e) => {
        setCertification({...certification, professional_domain: parseInt(e.target.value)})
    }

    const handleSetTitleOfQualification = (e) => {
        setCertification({...certification, title_of_qualification: e.target.value})
    }

    const handleSetCertificationObtained = (e) => {
        setCertification({...certification, certification_obtained: e.target.value})
    }

    const handleSetBeginningDate = (e) => {
        setCertification({...certification, beginning_date: e.target.value})
    }

    const handleSetEndingDate = (e) => {
        setCertification({...certification, ending_date: e.target.value})
    }

    const handleSetShortDescription = (e) => {
        setCertification({...certification, short_description: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData(url, {
            method: method,
            body: JSON.stringify({
                domain: isNaN(certification.professional_domain) ? certification.professional_domain.id_domain : certification.professional_domain,
                title_of_qualification: certification.title_of_qualification,
                certification_obtained: parseInt(certification.certification_obtained),
                beginning_date: moment(certification.beginning_date).format('DD/MM/YYYY'),
                ending_date: moment(certification.ending_date).format('DD/MM/YYYY'),
                short_description: certification.short_description,
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/job-profile`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    {certificationId !== null && <h5>Certification : {certification?.title_of_qualification}</h5>}
                    {certificationId === null && <h5>Add Certification</h5>}

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>Professional Domain <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-certificate"></i>
                                    <select style={{ paddingLeft: '2em' }} id="domain" value={certification?.professional_domain?.id_domain} onChange={handleSetProfessionalDomain}>
                                        <option value="">Select professional domain</option>

                                        {domains.map(domain => {
                                            return <option key={domain.id_domain} value={domain.id_domain} defaultChecked={certification?.professional_domain?.id_domain === domain.id_domain}>
                                                {domain.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.domain && <span className="text-danger">
                                    {errors.domain[0]}
                                </span>}
                            </div>
                            <div className="col-lg-6">
                                <span>Title Of Qualification <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-certificate"></i>
                                    <input type="text" id="title_of_qualification" defaultValue={certification?.title_of_qualification ?? ''} onChange={handleSetTitleOfQualification} />
                                </label>

                                {errors.title_of_qualification && <span className="text-danger">
                                    {errors.title_of_qualification[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Certification Obtained <span style={{ color: '#ff8d00' }}>*</span></span>

                                <div className="d-flex align-items-center mt-2">
                                    <label className="d-flex flex-row align-items-center me-4" style={{ width: 'inherit' }}>
                                        <input type="radio" name="certification_obtained" value="1" checked={certification?.certification_obtained === "1"} onChange={handleSetCertificationObtained} />
                                        <span className="ms-2">Yes</span>
                                    </label>

                                    <label className="d-flex flex-row align-items-center" style={{ width: 'inherit' }}>
                                        <input type="radio" name="certification_obtained" value="0" checked={certification?.certification_obtained === "0"} onChange={handleSetCertificationObtained} />
                                        <span className="ms-2">No</span>
                                    </label>
                                </div>

                                {errors.certification_obtained && <span className="text-danger">
                                    {errors.certification_obtained[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Beginning Date <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-calendar"></i>
                                    <input type="date" id="beginning_date" defaultValue={beginningDate ?? ''} onChange={handleSetBeginningDate} />
                                </label>

                                {errors.beginning_date && <span className="text-danger">
                                    {errors.beginning_date[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Ending Date <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-calendar"></i>
                                    <input type="date" id="ending_date" defaultValue={endingDate ?? ''} onChange={handleSetEndingDate} />
                                </label>

                                {errors.ending_date && <span className="text-danger">
                                    {errors.ending_date[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Short Description</span>

                                <label className="mt-1">
                                    <i className="fa fa-pen"></i>
                                    <input type="text" id="short_description" defaultValue={certification?.short_description ?? ''} onChange={handleSetShortDescription} />
                                </label>

                                {errors.short_description && <span className="text-danger">
                                    {errors.short_description[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
