export const API_URL = 'https://ats.jdavprojects.com/api'

export const HTTP_HEADERS = (token = '') => {
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers': '*',
        // 'Access-Control-Allow-Methods': '*',
    }

    if (token !== '') {
        headers['Authorization'] = 'Bearer ' + token
    }

    return headers
}

export const INVALID_DATA_RESPONSE_MESSAGE = 'The given data was invalid.'
