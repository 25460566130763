import React, {useState, useEffect} from 'react'
import '../style.css'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../config";
import SweetAlert from 'sweetalert2-react'
import { MultiSelect } from "react-multi-select-component"
import {fetchData} from "../../../services";

export const AdditionalInformation = ({display, toggleDisplay, compte}) =>  {
    const [errors, setErrors] = useState([])
    const [hobbies, setHobbies] = useState([])
    const [sports, setSports] = useState([])
    const [hobbiesOptions, setHobbiesOptions] = useState([])
    const [sportsOptions, setSportsOptions] = useState([])
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        let _hobbies = []
        let _sports = []

        compte?.hobby?.forEach(
            _hobby => _hobbies.push({label: _hobby.en_US, value: _hobby.id_hobby})
        )

        setHobbies(_hobbies)

        compte?.sport?.forEach(
            sport => _sports.push({label: sport.en_US, value: sport.id_sport})
        )

        setSports(_sports)
    }, [setHobbies, setSports, compte])

    useEffect(() => {
        fetchData('hobbies')
            .then(data => {
                let options = data.data.map(function (option) {
                    return {
                        label: option.en_US,
                        value: option.id_hobby
                    }
                })

                setHobbiesOptions(options)
            })
    } , [setHobbiesOptions])

    useEffect(() => {
        fetchData('sports')
            .then(data => {
                let options = data.data.map(function (option) {
                    return {
                        label: option.en_US,
                        value: option.id_sport
                    }
                })

                setSportsOptions(options)
            })
    } , [setSportsOptions])

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData('additional_information', {
            method: 'put',
            body: JSON.stringify({
                hobbies: hobbies.map(hobby => hobby.value),
                sports: sports.map(sport => sport.value)
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/details`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Additional Information</h5>

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>Hobbies</span>

                                <div className="mt-1 multiselect">
                                    <i className="far fa-flag"></i>
                                    <MultiSelect options={hobbiesOptions} value={hobbies} onChange={setHobbies} labelledBy="Select" />
                                </div>

                                {errors.hobbies && <span className="text-danger">
                                    {errors.hobbies[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Sports</span>

                                <div className="mt-1 multiselect">
                                    <i className="far fa-flag"></i>
                                    <MultiSelect options={sportsOptions} value={sports} onChange={setSports} labelledBy="Select" />
                                </div>

                                {errors.sports && <span className="text-danger">
                                    {errors.sports[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
