import {API_URL, HTTP_HEADERS} from "../config";
import {getAuth, rememberMe, forgetAuth, setAuth} from "./auth";

export const fetchData = async (url, params = {}, checkLogin = true) => {
    const auth = checkLogin ? getAuth() : {}

    const response = await fetch(`${API_URL}/${url}`, {
        ...params, headers: HTTP_HEADERS(auth?.access_token),
    })

    const data = await response.json()

    if (!checkLogin) {
        return data
    }

    if (data.error === 'Unauthenticated.') {
        if (!rememberMe()) {
            forgetAuth()
        }

        const _response = await fetch(`${API_URL}/refresh_token?token=${auth?.access_token}`, {
            headers: HTTP_HEADERS(auth?.access_token),
        })

        const _data = await _response.json()

        if (_data.error === 'Unauthenticated.') {
            forgetAuth()
        }

        auth.access_token = _data.access_token

        setAuth(auth)

        return fetchData(url, params)
    }

    return data
}
