import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";
import SweetAlert from 'sweetalert2-react'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../../config";
import moment from "moment";

export const ExperienceEdit = ({experienceId, display, toggleDisplay}) => {
    const url = experienceId === null ? 'experience' : `experience/${experienceId}`
    const method = experienceId === null ? 'post' : 'put'

    const [experience, setExperience] = useState({})
    const [jobTypes, setJobTypes] = useState([])
    const [typeOfCompanies, setTypeOfCompanies] = useState([])
    const [numberOfEmployees, setNumberOfEmployees] = useState([])
    const [sectors, setSectors] = useState([])
    const [salaryExpectations, setSalaryExpectations] = useState([])
    const [beginningDate, setBeginningDate] = useState('')
    const [endingDate, setEndingDate] = useState('')
    const [cities, setCities] = useState([])
    const [errors, setErrors] = useState([])
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        if (experienceId !== null) {
            fetchData(`experience/${experienceId}`)
                .then(data => {
                    setExperience(data.data)
                    setBeginningDate(moment(data.data.beginning_date).format('YYYY-MM-DD'))
                    setEndingDate(moment(data.data.ending_date).format('YYYY-MM-DD'))
                })
        }
    }, [setExperience, experienceId])

    useEffect(() => {
        fetchData('type_of_contract')
            .then(data => setJobTypes(data.data))
    }, [setJobTypes])

    useEffect(() => {
        fetchData('types_of_companies')
            .then(data => setTypeOfCompanies(data.data))
    }, [setTypeOfCompanies])

    useEffect(() => {
        fetchData('workforces')
            .then(data => setNumberOfEmployees(data.data))
    }, [setNumberOfEmployees])

    useEffect(() => {
        fetchData('sectors')
            .then(data => setSectors(data.data))
    }, [setSectors])

    useEffect(() => {
            fetchData('salary_expectations')
            .then(data => setSalaryExpectations(data.data))
    }, [setSalaryExpectations])

    useEffect(() => {
        fetchData('cities')
            .then(data => setCities(data.data))
    }, [setCities])

    const handleSetJobType = (e) => {
        setExperience({...experience, job_type: parseInt(e.target.value)})
    }

    const handleSetTypeOfCompany = (e) => {
        setExperience({...experience, type_of_business: parseInt(e.target.value)})
    }

    const handleSetNumberOfEmployees = (e) => {
        setExperience({...experience, number_of_employees: parseInt(e.target.value)})
    }

    const handleSetSector = (e) => {
        setExperience({...experience, sector: parseInt(e.target.value)})
    }

    const handleSetSalaryExpectation = (e) => {
        setExperience({...experience, salary_range: parseInt(e.target.value)})
    }

    const handleSetBeginningDate = (e) => {
        setExperience({...experience, beginning_date: e.target.value})
    }

    const handleSetEndingDate = (e) => {
        setExperience({...experience, ending_date: e.target.value})
    }

    const handleSetStateCity = (e) => {
        setExperience({...experience, state_city: parseInt(e.target.value)})
    }

    const handleSetOtherStateCity = (e) => {
        setExperience({...experience, other_state_city: e.target.value})
    }

    const handleSetJobTitle = (e) => {
        setExperience({...experience, job_title: e.target.value})
    }

    const handleSetAssignmentLocation = (e) => {
        setExperience({...experience, assignment_location: e.target.value})
    }

    const handleSetNameOfEmployee = (e) => {
        setExperience({...experience, name_of_employee: e.target.value})
    }

    const handleSetCurrentJob = (e) => {
        setExperience({...experience, current_job: e.target.value})
    }

    const handleSetEmployeePhoneNumber = (e) => {
        setExperience({...experience, employee_phone_number: e.target.value})
    }

    const handleSetEmployeeWebsiteBlog = (e) => {
        setExperience({...experience, employee_website_blog: e.target.value})
    }

    const handleSetDescription = (e) => {
        setExperience({...experience, description_of_duties_and_responsabilities: e.target.value})
    }

    const handleSetKeyAchievements = (e) => {
        setExperience({...experience, key_achievements: e.target.value})
    }

    const handleSetReasonForLeavingThePost = (e) => {
        setExperience({...experience, reasons_for_leaving_the_post: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData(url, {
            method: method,
            body: JSON.stringify({
                job_type: isNaN(experience.job_type) ? experience.job_type?.id_job_type : experience.job_type,
                type_of_business: isNaN(experience.type_of_business) ? experience.type_of_business?.id_company_type : experience.type_of_business,
                number_of_employees: isNaN(experience.number_of_employees) ? experience.number_of_employees?.id_effectif : experience.number_of_employees,
                sector: isNaN(experience.sector) ? experience.sector?.id_sector : experience.sector,
                salary_range: isNaN(experience.salary_range) ? experience.salary_range?.id_salary_expectation : experience.salary_range,
                state_city: isNaN(experience.state_city) ? experience.state_city?.id_city : experience.state_city,
                other_state_city: experience.other_state_city,
                beginning_date: moment(experience.beginning_date).format('DD/MM/YYYY'),
                ending_date: moment(experience.ending_date).format('DD/MM/YYYY'),
                assignment_location: experience.assignment_location,
                job_title: experience.job_title,
                name_of_employee: experience.name_of_employee,
                current_job: parseInt(experience.current_job),
                employee_phone_number: experience.employee_phone_number,
                employee_website_blog: experience.employee_website_blog,
                description_of_duties_and_responsabilities: experience.description_of_duties_and_responsabilities,
                key_achievements: experience.key_achievements,
                reasons_for_leaving_the_post: experience.reasons_for_leaving_the_post,
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/job-profile`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content" style={{ marginInline: '30em' }}>
                <div className="modal-header">
                    {experienceId !== null && <h5>Experience : {experience?.title_of_qualification}</h5>}
                    {experienceId === null && <h5>Add Experience</h5>}

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-4">
                                <span>Job Type <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-building"></i>
                                    <select style={{ paddingLeft: '2em' }} id="job_type" value={experience?.job_type?.id_job_type} onChange={handleSetJobType}>
                                        <option value="">Select job type</option>

                                        {jobTypes.map(jobType => {
                                            return <option key={jobType.id_job_type} value={jobType.id_job_type} defaultChecked={experience?.job_type?.id_job_type === jobType.id_jobType}>
                                                {jobType.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.job_type && <span className="text-danger">
                                    {errors.job_type[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Type Of Company <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-building"></i>
                                    <select style={{ paddingLeft: '2em' }} id="type_of_business" value={experience?.type_of_business?.id_company_type} onChange={handleSetTypeOfCompany}>
                                        <option value="">Select type of company</option>

                                        {typeOfCompanies.map(typeOfCompany => {
                                            return <option key={typeOfCompany.id_company_type} value={typeOfCompany.id_company_type} defaultChecked={experience?.type_of_business?.id_company_type === typeOfCompany.id_company_type}>
                                                {typeOfCompany.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.type_of_business && <span className="text-danger">
                                    {errors.type_of_business[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Number Of Employees</span>

                                <label className="mt-1">
                                    <i className="fa fa-building"></i>
                                    <select style={{ paddingLeft: '2em' }} id="number_of_employees" value={experience?.number_of_employees?.id_effectif} onChange={handleSetNumberOfEmployees}>
                                        <option value="">Select number of employees</option>

                                        {numberOfEmployees.map(numberOfEmployee => {
                                            return <option key={numberOfEmployee.id_effectif} value={numberOfEmployee.id_effectif} defaultChecked={experience?.number_of_employees?.id_effectif === numberOfEmployee.id_effectif}>
                                                {numberOfEmployee.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.number_of_employees && <span className="text-danger">
                                    {errors.number_of_employees[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Sector <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-building"></i>
                                    <select style={{ paddingLeft: '2em' }} id="sector" value={experience?.sector?.id_sector} onChange={handleSetSector}>
                                        <option value="">Select sector</option>

                                        {sectors.map(sector => {
                                            return <option key={sector.id_sector} value={sector.id_sector} defaultChecked={experience?.sector?.id_sector === sector.id_sector}>
                                                {sector.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.sector && <span className="text-danger">
                                    {errors.sector[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Salary Range</span>

                                <label className="mt-1">
                                    <i className="fa fa-money-bill"></i>
                                    <select style={{ paddingLeft: '2em' }} id="salary_range" value={experience?.salary_range?.id_salary_expectation} onChange={handleSetSalaryExpectation}>
                                        <option value="">Select salary range</option>

                                        {salaryExpectations.map(salaryExpectation => {
                                            return <option key={salaryExpectation.id_salary_expectation} value={salaryExpectation.id_salary_expectation} defaultChecked={experience?.salary_range?.id_salary_expectation === salaryExpectation.id_salary_expectation}>
                                                {salaryExpectation.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.salary_range && <span className="text-danger">
                                    {errors.salary_range[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>State City <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-flag"></i>
                                    <select style={{ paddingLeft: '2em' }} id="state_city" value={experience?.State_city?.id_city} onChange={handleSetStateCity}>
                                        <option value="">Select state city</option>

                                        {cities.map(city => {
                                            return <option key={city.id_city} value={city.id_city} defaultChecked={experience?.state_city?.id_city === city.id_city}>
                                                {city.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.state_city && <span className="text-danger">
                                    {errors.state_city[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Other State City</span>

                                <label className="mt-1">
                                    <i className="fa fa-flag"></i>
                                    <input type="text" id="other_state_city" defaultValue={experience?.other_state_city ?? ''} onChange={handleSetOtherStateCity} />
                                </label>

                                {errors.other_state_city && <span className="text-danger">
                                    {errors.other_state_city[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Assignment Location</span>

                                <label className="mt-1">
                                    <i className="fa fa-building"></i>
                                    <input type="text" id="assignment_location" defaultValue={experience?.assignment_location ?? ''} onChange={handleSetAssignmentLocation} />
                                </label>

                                {errors.assignment_location && <span className="text-danger">
                                    {errors.assignment_location[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Job Title <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-user-tie"></i>
                                    <input type="text" id="job_title" defaultValue={experience?.job_title ?? ''} onChange={handleSetJobTitle} />
                                </label>

                                {errors.job_title && <span className="text-danger">
                                    {errors.job_title[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Name Of Employee <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-user"></i>
                                    <input type="text" id="name_of_employee" defaultValue={experience?.name_of_employee ?? ''} onChange={handleSetNameOfEmployee} />
                                </label>

                                {errors.name_of_employee && <span className="text-danger">
                                    {errors.name_of_employee[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Current Job <span style={{ color: '#ff8d00' }}>*</span></span>

                                <div className="d-flex align-items-center mt-2">
                                    <label className="d-flex flex-row align-items-center me-4" style={{ width: 'inherit' }}>
                                        <input type="radio" name="current_job" value="1" checked={experience?.current_job === "1"} onChange={handleSetCurrentJob} />
                                        <span className="ms-2">Yes</span>
                                    </label>

                                    <label className="d-flex flex-row align-items-center" style={{ width: 'inherit' }}>
                                        <input type="radio" name="current_job" value="0" checked={experience?.current_job === "0"} onChange={handleSetCurrentJob} />
                                        <span className="ms-2">No</span>
                                    </label>
                                </div>

                                {errors.current_job && <span className="text-danger">
                                    {errors.current_job[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Employee Phone Number</span>

                                <label className="mt-1">
                                    <i className="fa fa-phone"></i>
                                    <input type="text" id="employee_phone_number" defaultValue={experience?.employee_phone_number ?? ''} onChange={handleSetEmployeePhoneNumber} />
                                </label>

                                {errors.employee_phone_number && <span className="text-danger">
                                    {errors.employee_phone_number[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Employee Website</span>

                                <label className="mt-1">
                                    <i className="fa fa-globe"></i>
                                    <input type="text" id="employee_website_blog" defaultValue={experience?.employee_website_blog ?? ''} onChange={handleSetEmployeeWebsiteBlog} />
                                </label>

                                {errors.employee_website_blog && <span className="text-danger">
                                    {errors.employee_website_blog[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Beginning Date <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-calendar"></i>
                                    <input type="date" id="beginning_date" defaultValue={beginningDate ?? ''} onChange={handleSetBeginningDate} />
                                </label>

                                {errors.beginning_date && <span className="text-danger">
                                    {errors.beginning_date[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Ending Date <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-calendar"></i>
                                    <input type="date" id="ending_date" defaultValue={endingDate ?? ''} onChange={handleSetEndingDate} />
                                </label>

                                {errors.ending_date && <span className="text-danger">
                                    {errors.ending_date[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Description Of Duties And Responsabilities</span>
                                <textarea id="description_of_duties_and_responsabilities" className="w-100" rows="3" onChange={handleSetDescription} defaultValue={experience?.description_of_duties_and_responsabilities}></textarea>

                                {errors.description_of_duties_and_responsabilities && <span className="text-danger">
                                    {errors.description_of_duties_and_responsabilities[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Key Achievements</span>
                                <textarea id="key_achievements" className="w-100" rows="3" onChange={handleSetKeyAchievements} defaultValue={experience?.key_achievements}></textarea>

                                {errors.key_achievements && <span className="text-danger">
                                    {errors.key_achievements[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4">
                                <span>Reasons For Leaving The Post</span>
                                <textarea id="reasons_for_leaving_the_post" className="w-100" rows="3" onChange={handleSetReasonForLeavingThePost} defaultValue={experience?.reasons_for_leaving_the_post}></textarea>

                                {errors.reasons_for_leaving_the_post && <span className="text-danger">
                                    {errors.reasons_for_leaving_the_post[0]}
                                </span>}
                            </div>

                            <div className="col-lg-4 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
