import React, {useState} from 'react'
import '../style.css'
import SweetAlert from 'sweetalert2-react'
import {fetchData} from "../../../services";
import {toBase64} from "../../../utils";

export const CoverLetter = ({display, toggleDisplay}) =>  {
    const [coverLetter, setCoverLetter] = useState(null)
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    const handleSetCoverLetter = async (e) => {
        setCoverLetter(await toBase64(e.target.files[0]))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData('default_document', {
            method: 'post',
            body: JSON.stringify({
                lm: coverLetter,
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                setAlert({
                    type: data.success === true ? 'success' : 'error',
                    text: data.message,
                    show: true,
                    redirect: `${process.env.PUBLIC_URL}/candidate/details`
                })
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Cover Letter</h5>

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-12">
                                <label>
                                    <i className="fa fa-file"></i>
                                    <input type="file" id="lm" className="w-100" accept="application/pdf" onChange={handleSetCoverLetter} />
                                </label>
                            </div>

                            <div className="col-lg-12 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
