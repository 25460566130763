import React from 'react'
import {arrayToString} from "../../utils";

export function Sidebar({
    profile,
    toggleSocialNetwork,
    toggleJobInterest,
    toggleCurriculumVitae,
    toggleCoverLetter,
    certifications,
    computerSkills,
    languageSkills,
    trainings,
    educations,
    experiences
}) {
    return <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
        <div className="job_list-widget">
            <aside className="widget candidate-widget">
                <h3 className="widget-title"><i className="fa fa-user-check"></i>Job Interest</h3>
                <ul>
                    <li className="d-flex flex-column">
                        <b className="mr-5">Job Levels:</b>
                        <span className="text-dark fw-bold text-break">
                            {arrayToString(profile.compte?.job_level)}
                        </span>
                    </li>
                    <li className="d-flex flex-column">
                        <b className="mr-5">Functions:</b>
                        <span className="text-dark fw-bold text-break">
                            {arrayToString(profile.compte?.fonction)}
                        </span>
                    </li>
                    <li className="d-flex flex-column">
                        <b className="mr-5">Sectors:</b>
                        <span className="text-dark fw-bold text-break">
                            {arrayToString(profile.compte?.sector)}
                        </span>
                    </li>
                    <li className="d-flex flex-column">
                        <b className="mr-5">Job Types:</b>
                        <span className="text-dark fw-bold text-break">
                            {arrayToString(profile.compte?.job_type)}
                        </span>
                    </li>
                    <li className="d-flex flex-column">
                        <b className="mr-5">Advance Notice:</b>
                        <span className="text-dark fw-bold text-break">
                            {profile.compte?.advance_notice?.en_US}
                        </span>
                    </li>
                    <li className="d-flex flex-column">
                        <b className="mr-5">Mobilities:</b>
                        <span className="text-dark fw-bold text-break">
                            {arrayToString(profile.compte?.mobilities)}
                        </span>
                    </li>
                    <li className="d-flex flex-column">
                        <b className="mr-5">Salary Expectation:</b>
                        <span className="text-dark fw-bold text-break">
                            {profile?.compte?.salary_expectation?.en_US}
                        </span>
                    </li>
                    <li className="d-flex">
                        <button className="submit w-50 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => toggleJobInterest()}>
                            Edit
                        </button>
                    </li>
                </ul>
            </aside>
            <aside className="widget form-widget">
                <h3 className="widget-title"><i className="fa fa-user-tie"></i>Job Profile</h3>
                <ul>
                    <li className="d-flex justify-content-between align-items-center">
                        <b className="mr-5">Certifications</b>
                        <span style={{ fontWeight: 'bold', color: '#FF8D00FF' }}>
                            {certifications?.data?.length}
                        </span>
                    </li>
                    <li className="d-flex justify-content-between align-items-center">
                        <b className="mr-5">Computer Skills</b>
                        <span style={{ fontWeight: 'bold', color: '#FF8D00FF' }}>
                            {computerSkills?.data?.length}
                        </span>
                    </li>
                    <li className="d-flex justify-content-between align-items-center">
                        <b className="mr-5">Educations</b>
                        <span style={{ fontWeight: 'bold', color: '#FF8D00FF' }}>
                            {educations?.data?.length}
                        </span>
                    </li>
                    <li className="d-flex justify-content-between align-items-center">
                        <b className="mr-5">Experiences</b>
                        <span style={{ fontWeight: 'bold', color: '#FF8D00FF' }}>
                            {experiences?.data?.length}
                        </span>
                    </li>
                    <li className="d-flex justify-content-between align-items-center">
                        <b className="mr-5">Language Skills</b>
                        <span style={{ fontWeight: 'bold', color: '#FF8D00FF' }}>
                            {languageSkills?.data?.length}
                        </span>
                    </li>
                    <li className="d-flex justify-content-between align-items-center">
                        <b className="mr-5">Trainings</b>
                        <span style={{ fontWeight: 'bold', color: '#FF8D00FF' }}>
                            {trainings?.data?.length}
                        </span>
                    </li>
                    <li>
                        <div className="my-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <b className="mr-5">Profile Evaluation</b>

                                <span style={{ fontWeight: 'bold', color: '#FF8D00FF' }}>
                                    {parseInt(profile.compte?.profile_evaluation)} %
                                </span>
                            </div>

                            <div>
                                <div style={{
                                    backgroundColor: '#FF8D00FF',
                                    transition: 'width 200ms ease 0s',
                                    height: '10px',
                                    borderRadius: '3px',
                                    width: `${parseInt(profile.compte?.profile_evaluation)}%`
                                }}></div>
                            </div>
                        </div>
                    </li>

                    <li className="d-flex">
                        <button className="submit w-50 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="button" onClick={() => document.location.href = process.env.PUBLIC_URL + '/candidate/job-profile'}>
                            Edit
                        </button>
                    </li>
                </ul>
            </aside>
        </div>
        <aside className="widget widget-download">
            <ul className="download">
                <li className="d-flex align-items-center justify-content-between">
                    <span>Curriculum Vitae</span>

                    <div className="d-flex align-items-center">
                        {profile.compte?.cv && <a target="_blank" rel="noreferrer" href={profile.compte?.cv} style={{ marginRight: '1em' }}><i className="fa fa-download"></i></a>}
                        <button type="button" onClick={() => toggleCurriculumVitae()}><i className="fa fa-edit"></i></button>
                    </div>
                </li>

                <li className="d-flex align-items-center justify-content-between">
                    <span>Cover Letter</span>

                    <div className="d-flex align-items-center">
                        {profile.compte?.cover_letter && <a target="_blank" rel="noreferrer" href={profile.compte?.cover_letter} style={{ marginRight: '1em' }}><i className="fa fa-download"></i></a>}
                        <button type="button" onClick={() => toggleCoverLetter()}><i className="fa fa-edit"></i></button>
                    </div>
                </li>
            </ul>
        </aside>
        <div className="overview-box">
            <div className="title">
                <div className="d-sm-flex justify-content-between align-items-center mb_10">
                    <p className="mb-10 fw-bold">Social Network :</p>
                    <div className="social-icons circle mb-10">
                        <ul className="list-inline">
                            {profile?.compte?.network?.map(function(network, i) {
                                if (network.social_network.name === 'Facebook') {
                                    return <li key={i}>
                                        <a href={network.social_network.account_url_or_username} rel="noreferrer" aria-label="facebook" target="_blank">
                                            <i className="ti ti-facebook"></i>
                                        </a>
                                    </li>
                                }

                                if (network.social_network.name === 'Linkedin') {
                                    return <li key={i}>
                                        <a href={network.social_network.account_url_or_username} rel="noreferrer" aria-label="linkedin" target="_blank">
                                            <i className="ti ti-linkedin"></i>
                                        </a>
                                    </li>
                                }

                                if (network.social_network.name === 'Twitter') {
                                    return <li key={i}>
                                        <a href={network.social_network.account_url_or_username} rel="noreferrer" aria-label="twitter" target="_blank">
                                            <i className="ti ti-twitter-alt"></i>
                                        </a>
                                    </li>
                                }

                                return ''
                            })}

                            <li className="position-relative" style={{ marginLeft: '1em' }}>
                                <button className="position-absolute" style={{ top: '-16px', backgroundColor: '#f7f7f7', boxShadow: 'none', fontSize: '1.2rem' }} onClick={() => toggleSocialNetwork()}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
