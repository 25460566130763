import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";
import moment from "moment";

export const TrainingShow = ({trainingId, display, toggleDisplay}) => {
    const [training, setTraining] = useState({})

    useEffect(() => {
        fetchData(`training/${trainingId}`)
            .then(data => setTraining(data.data))
    }, [setTraining, trainingId])

    return <div className={"modal-container " + (display ? 'show' : '')}>
        <div className="modal-content">
            <div className="modal-header">
                <h5>Training : {training?.domain?.en_US}</h5>

                <button type="button" onClick={() => toggleDisplay()} title="Close">
                    <i className="fa fa-times fa-lg"></i>
                </button>
            </div>

            <div className="modal-body">
                <div className="row gy-3">
                    <div className="col-lg-6">
                        <p className="mb-0">Domain</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {training?.domain?.en_US ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Other Domain</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {training?.other_domain ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Specific Training</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {training?.specific_training ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <p className="mb-0">Date Completed</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {moment(training?.date_completed).format('LL') ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <p className="mb-0">Attestation Document</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {(training?.attestation_url && <a href={training?.attestation_url}>Click to download</a>) ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <p className="mb-0">Short Description</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {training?.short_description ?? '-'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
