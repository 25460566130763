import React, { Component } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <Router>
                <nav className="main-menu">         
                    <ul className="menu">
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/'} className="mega-menu-link">Home</a>
                            <ul className="mega-submenu">
                                <li><a href={process.env.PUBLIC_URL + '/'}>Job Seeker</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/home2'}>Job Consultancy</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/home3'}>Human Resource</a></li>
                            </ul>
                        </li>
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/about-us'} className="mega-menu-link">Pages</a>
                            <ul className="mega-submenu">
                                <li><a href={process.env.PUBLIC_URL + '/about-us'}>About Us</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/services'}>Services</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/services-details'}>Services Details</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/career-01'}>Career</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/login'}>Login</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/register'}>Register</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/error-404'}>Error 404</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/error-405'}>Error 405</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/contact-01'}>Contact us 01</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/contact-02'}>Contact us 02</a></li>
                            </ul>
                        </li>
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/job-list'} className="mega-menu-link">Jobs</a>
                            <ul className="mega-submenu">
                                <li><a href={process.env.PUBLIC_URL + '/job-list'}>Job List</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/job-details'}>Job Details</a></li>
                            </ul>
                        </li>
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/employers-list'} className="mega-menu-link">Employers</a>
                            <ul className="mega-submenu">
                                <li><a href={process.env.PUBLIC_URL + '/employers-list'}>Employers List</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/employers-details'}>Employers Details</a></li>
                            </ul>
                        </li>
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/candidate/list'} className="mega-menu-link">Candidates</a>
                            <ul className="mega-submenu">
                                <li><a href={process.env.PUBLIC_URL + '/candidate/list'}>Candidate List</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/candidate/details'}>Candidate Details</a></li>
                            </ul>
                        </li>                    
                        <li className="mega-menu-item">
                            <a href={process.env.PUBLIC_URL + '/blog-classic'} className="mega-menu-link">Blog</a>
                            <ul className="mega-submenu">
                                <li><a href={process.env.PUBLIC_URL + '/blog-classic'}>Blog Classic</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/blog-grid'}>Blog Grid</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/blog-details'}>Blog Details</a></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </Router>
        )
    }
}

export default Menu;
