export const arrayToString = (array) => {
    if (!array || array?.length === 0) {
        return '-'
    }

    let result = ''
    array?.map(arr => result += arr.en_US + ', ')

    return result.slice(0, -2)
}

export const toggleSection = (e) => {
    const icon = e.target.children[0]
    const section = document.querySelector(e.target.dataset.target)

    if (icon.classList.contains('fa-chevron-down')) {
        icon.classList.remove('fa-chevron-down')
        icon.classList.add('fa-chevron-up')
        section.classList.remove('d-none')
    } else {
        icon.classList.remove('fa-chevron-up')
        icon.classList.add('fa-chevron-down')
        section.classList.add('d-none')
    }
}

//https://stackoverflow.com/a/57272491
export const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
})
