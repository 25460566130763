import React, { Component } from 'react';
import Header from '../../components/layout/Header';
import PageHeader from "../../components/layout/PageHeader";
import { Footer } from '../../components/layout/Footer';
import {API_URL, HTTP_HEADERS, INVALID_DATA_RESPONSE_MESSAGE} from "../../config";
import SweetAlert from 'sweetalert2-react'

export default class ResetPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            alert: {
                type: '',
                text: '',
                show: false,
                redirect: ''
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSetEmail = this.handleSetEmail.bind(this)
    }

    handleSetEmail(e) {
        this.setState({email: e.target.value})
    }
    
    handleSubmit(e) {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Reset password'

        fetch(`${API_URL}/reset_password`, {
            method: 'post',
            headers: HTTP_HEADERS(),
            body: JSON.stringify({
                email: this.state.email
            }),
        })
            .then(res => res.json())
            .then(data => {
                e.target.innerHTML = 'Reset password'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    this.setState({
                        alert: {
                            type: 'error',
                            text: data.errors.email,
                            show: true,
                            redirect: `${process.env.PUBLIC_URL}/reset-password`
                        }
                    })
                } else {
                    this.setState({
                        alert: {
                            type: 'success',
                            text: data.message,
                            show: true,
                            redirect: `${process.env.PUBLIC_URL}/login`
                        }
                    })
                }
            })
    }
    
    render() {
        return <>
            <SweetAlert
                show={this.state.alert.show}
                text={this.state.alert.text}
                type={this.state.alert.type}
                onConfirm={() => document.location.href = this.state.alert.redirect}
            />

            <div className="site-main">
                <Header/>

                {/* PageHeader */}
                <PageHeader
                    title="Reset passowrd"
                    breadcrumb="Reset password"
                />
                {/* PageHeader end */}

                {/* login */}
                <div className="ttm-row login-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <div className="text-center mb-20">
                                            <h3>Reset Your Account Password</h3>
                                        </div>

                                        <div>
                                            <form id="login_form" className="login_form wrap-form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label>
                                                            <i className="ti ti-email"></i>
                                                            <input type="email" id="email" placeholder="Email Address" value={this.state.email} onChange={this.handleSetEmail} />
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 mx-auto">
                                                        <label className="mb-0">
                                                            <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="submit" onClick={this.handleSubmit}>
                                                                Reset password
                                                            </button>
                                                        </label>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* login end */}


                <Footer/>

            </div>
        </>
      }
}
