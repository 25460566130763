import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";
import SweetAlert from 'sweetalert2-react'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../../config";

export const ComputerSkillEdit = ({computerSkillId, display, toggleDisplay}) => {
    const url = computerSkillId === null ? 'computer_skills' : `computer_skills/${computerSkillId}`
    const method = computerSkillId === null ? 'post' : 'put'

    const [computerSkill, setComputerSkill] = useState({})
    const [domains, setDomains] = useState([])
    const [systems, setSystems] = useState([])
    const [levels, setLevels] = useState([])
    const [errors, setErrors] = useState([])
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        if (computerSkillId !== null) {
            fetchData(`computer_skills/${computerSkillId}`)
                .then(data => setComputerSkill(data.data))
        }
    }, [setComputerSkill, computerSkillId])

    useEffect(() => {
        fetchData('computer_science_domains')
            .then(data => setDomains(data.data))
    }, [setDomains])

    useEffect(() => {
        fetchData('software_levels')
            .then(data => setLevels(data.data))
    }, [setLevels])

    const handleSetKnowledgeDomain = (e) => {
        setComputerSkill({...computerSkill, knowledge_domain: parseInt(e.target.value)})
        handleGetApplicationSystem(parseInt(e.target.value))
    }

    const handleSetApplicationSystem = (e) => {
        setComputerSkill({...computerSkill, application_system: parseInt(e.target.value)})
    }

    const handleSetOtherApplicationSystem = (e) => {
        setComputerSkill({...computerSkill, other_application_system: e.target.value})
    }

    const handleSetLevel = (e) => {
        setComputerSkill({...computerSkill, level: parseInt(e.target.value)})
    }

    const handleSetShortDescription = (e) => {
        setComputerSkill({...computerSkill, description_of_relevant_expertise: e.target.value})
    }

    const handleGetApplicationSystem = (domainId) => {
        fetchData(`computer_science_domains/${domainId}/tools`)
            .then(data => setSystems(data.data))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData(url, {
            method: method,
            body: JSON.stringify({
                knowledge_area: isNaN(computerSkill.knowledge_domain) ? computerSkill.knowledge_domain?.id_computer_science_domain : computerSkill.knowledge_domain,
                application_system: computerSkill.application_system,
                other_application_system: computerSkill.other_application_system,
                level: isNaN(computerSkill.level) ? computerSkill.level?.id_software_level: computerSkill.level,
                descr: computerSkill.description_of_relevant_expertise,
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/job-profile`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    {computerSkillId !== null && <h5>Computer Skill : {computerSkill?.knowledge_domain?.en_US}</h5>}
                    {computerSkillId === null && <h5>Add Computer Skill</h5>}

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>Knowledge Domain</span>

                                <label className="mt-1">
                                    <i className="fa fa-laptop"></i>
                                    <select style={{ paddingLeft: '2em' }} id="domain" value={computerSkill?.knowledge_domain?.id_computer_science_domain} onChange={handleSetKnowledgeDomain}>
                                        <option value="">Select professional domain</option>

                                        {domains.map(domain => {
                                            return <option key={domain.id_computer_science_domain} value={domain.id_computer_science_domain} defaultChecked={computerSkill?.knowledge_domain === domain.id_computer_science_domain}>
                                                {domain.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.knowledge_domain && <span className="text-danger">
                                    {errors.knowledge_domain[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Application System</span>

                                <label className="mt-1">
                                    <i className="fa fa-laptop"></i>
                                    <select style={{ paddingLeft: '2em' }} id="system" value={computerSkill?.application_system_and_tools} onChange={handleSetApplicationSystem}>
                                        <option value="">Select application system</option>

                                        {systems?.map(domain => {
                                            return <option key={domain.id_tool} value={domain.id_tool} defaultChecked={computerSkill?.application_system_and_tools === domain.id_tool}>
                                                {domain.name}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.application_system_and_tools && <span className="text-danger">
                                    {errors.application_system_and_tools[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Other Application System</span>

                                <label className="mt-1">
                                    <i className="fa fa-laptop"></i>
                                    <input type="text" id="other_application_system" defaultValue={computerSkill?.other_application_system ?? ''} onChange={handleSetOtherApplicationSystem} />
                                </label>

                                {errors.other_application_system && <span className="text-danger">
                                    {errors.other_application_system[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Level <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-list-ol"></i>
                                    <select style={{ paddingLeft: '2em' }} id="level" value={computerSkill?.level?.id_software_level} onChange={handleSetLevel}>
                                        <option value="">Select level</option>

                                        {levels?.map(level => {
                                            return <option key={level.id_software_level} value={level.id_software_level} defaultChecked={computerSkill?.level === level.id_software_level}>
                                                {level.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.level && <span className="text-danger">
                                    {errors.level[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Short Description</span>

                                <label className="mt-1">
                                    <i className="fa fa-pen"></i>
                                    <input type="text" id="description_of_relevant_expertise" defaultValue={computerSkill?.description_of_relevant_expertise ?? ''} onChange={handleSetShortDescription} />
                                </label>

                                {errors.description_of_relevant_expertise && <span className="text-danger">
                                    {errors.description_of_relevant_expertise[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6"></div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
