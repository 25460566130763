import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";
import moment from "moment";

export const CertificationShow = ({certificationId, display, toggleDisplay}) => {
    const [certification, setCertification] = useState({})

    useEffect(() => {
        fetchData(`certifications/${certificationId}`)
            .then(data => setCertification(data.data))
    }, [setCertification, certificationId])

    return <div className={"modal-container " + (display ? 'show' : '')}>
        <div className="modal-content">
            <div className="modal-header">
                <h5>Certification : {certification?.title_of_qualification}</h5>

                <button type="button" onClick={() => toggleDisplay()} title="Close">
                    <i className="fa fa-times fa-lg"></i>
                </button>
            </div>

            <div className="modal-body">
                <div className="row gy-3">
                    <div className="col-lg-6">
                        <p className="mb-0">Professional Domain</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {certification?.professional_domain?.en_US ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Title Of Qualification</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {certification?.title_of_qualification ?? '-'}
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb-0">Certification Obtained</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {parseInt(certification?.certification_obtained) === 1 ? 'Yes' : 'No'}
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <p className="mb-0">Beginning Date</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {moment(certification?.beginning_date).format('LL') ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <p className="mb-0">Ending Date</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {moment(certification?.ending_date).format('LL') ?? '-'}
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <p className="mb-0">Short Description</p>
                        <p className="text-dark fs-6 fw-bold mb-0 text-break">
                            {certification?.short_description ?? '-'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
