import React, {useEffect, useState} from 'react'
import {fetchData} from "../../../../services";
import SweetAlert from 'sweetalert2-react'
import {INVALID_DATA_RESPONSE_MESSAGE} from "../../../../config";
import moment from "moment/moment";

export const EducationEdit = ({educationId, display, toggleDisplay}) => {
    const url = educationId === null ? 'educations' : `educations/${educationId}`
    const method = educationId === null ? 'post' : 'put'

    const [education, setEducation] = useState({})
    const [degrees, setDegrees] = useState([])
    const [levels, setLevels] = useState([])
    const [domains, setDomains] = useState([])
    const [cities, setCities] = useState([])
    const [errors, setErrors] = useState([])
    const [beginningDate, setBeginningDate] = useState('')
    const [endingDate, setEndingDate] = useState('')
    const [alert, setAlert] = useState({
        type: '',
        text: '',
        show: false,
        redirect: ''
    })

    useEffect(() => {
        if (educationId !== null) {
            fetchData(`educations/${educationId}`)
                .then(data => {
                    setEducation(data.data)
                    setBeginningDate(moment(data.data.beginning_date).format('YYYY-MM-DD'))
                    setEndingDate(moment(data.data.ending_date).format('YYYY-MM-DD'))
                })
        }
    }, [setEducation, educationId])

    useEffect(() => {
        fetchData('trainings')
            .then(data => setDegrees(data.data))
    }, [setDegrees])

    useEffect(() => {
        fetchData('education_levels')
            .then(data => setLevels(data.data))
    }, [setLevels])

    useEffect(() => {
        fetchData('education_domains')
            .then(data => setDomains(data.data))
    }, [setDomains])

    useEffect(() => {
        fetchData('cities')
            .then(data => setCities(data.data))
    }, [setCities])

    const handleSetDegree = (e) => {
        setEducation({...education, degree: parseInt(e.target.value)})
    }

    const handleSetLevel = (e) => {
        setEducation({...education, level: parseInt(e.target.value)})
    }

    const handleSetCourseOfStudy = (e) => {
        setEducation({...education, course_of_study: parseInt(e.target.value)})
    }

    const handleSetOthersCourseOfStudy = (e) => {
        setEducation({...education, others_course_of_study: e.target.value})
    }

    const handleSetDegreeObtained = (e) => {
        setEducation({...education, degree_obtained: e.target.value})
    }

    const handleSetBeginningDate = (e) => {
        setEducation({...education, beginning_date: e.target.value})
    }

    const handleSetEndingDate = (e) => {
        setEducation({...education, ending_date: e.target.value})
    }

    const handleSetShortDescription = (e) => {
        setEducation({...education, short_description: e.target.value})
    }

    const handleSetInstitution = (e) => {
        setEducation({...education, institution: e.target.value})
    }

    const handleSetStateCity = (e) => {
        setEducation({...education, state_city: e.target.value})
    }

    const handleSetOtherCity = (e) => {
        setEducation({...education, other_city: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Save changes'

        fetchData(url, {
            method: method,
            body: JSON.stringify({
                degree: isNaN(education.degree) ? education.degree?.id_training : education.degree,
                state_city: isNaN(education.state_city) ? education.state_city?.id_city : education.state_city,
                other_city: education.other_city,
                course_of_study: isNaN(education.course_of_study) ? education.course_of_study?.id_domain : education.course_of_study,
                others_course_of_study: education.others_course_of_study,
                level: isNaN(education.level) ? education.level?.id_education_level : education.level,
                short_description: education.short_description,
                institution: education.institution,
                beginning_date: moment(education.beginning_date).format('DD/MM/YYYY'),
                ending_date: moment(education.ending_date).format('DD/MM/YYYY'),
                degree_obtained: parseInt(education.degree_obtained),
            }),
        })
            .then(data => {
                e.target.innerHTML = 'Save changes'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    setErrors(data.errors)
                } else {
                    setAlert({
                        type: data.success === true ? 'success' : 'error',
                        text: data.message,
                        show: true,
                        redirect: `${process.env.PUBLIC_URL}/candidate/job-profile`
                    })
                }
            })
    }

    return <>
        <SweetAlert
            show={alert.show}
            text={alert.text}
            type={alert.type}
            onConfirm={() => document.location.href = alert.redirect}
        />

        <div className={"modal-container " + (display ? 'show' : '')}>
            <div className="modal-content">
                <div className="modal-header">
                    {educationId !== null && <h5>Education : {education?.degree?.en_US}</h5>}
                    {educationId === null && <h5>Add Education</h5>}

                    <button type="button" onClick={() => toggleDisplay()} title="Close">
                        <i className="fa fa-times fa-lg"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <form className="modal-form wrap-form">
                        <div className="row gy-3">
                            <div className="col-lg-6">
                                <span>Degree <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-certificate"></i>
                                    <select style={{ paddingLeft: '2em' }} id="degree" value={education?.degree?.id_training} onChange={handleSetDegree}>
                                        <option value="">Select degree</option>

                                        {degrees.map(degree => {
                                            return <option key={degree.id_training} value={degree.id_training} defaultChecked={education?.degree === degree.id_domain}>
                                                {degree.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.degree && <span className="text-danger">
                                    {errors.degree[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Level <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-list-ol"></i>
                                    <select style={{ paddingLeft: '2em' }} id="level" value={education?.level?.id_education_level} onChange={handleSetLevel}>
                                        <option value="">Select level</option>

                                        {levels.map(level => {
                                            return <option key={level.id_education_level} value={level.id_education_level} defaultChecked={education?.level === level.id_education_level}>
                                                {level.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.level && <span className="text-danger">
                                    {errors.level[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Course Of Study</span>

                                <label className="mt-1">
                                    <i className="fa fa-chalkboard"></i>
                                    <select style={{ paddingLeft: '2em' }} id="domain" value={education?.course_of_study?.id_domain} onChange={handleSetCourseOfStudy}>
                                        <option value="">Select coures of study</option>

                                        {domains.map(domain => {
                                            return <option key={domain.id_domain} value={domain.id_domain} defaultChecked={education?.course_of_study === domain.id_domain}>
                                                {domain.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.course_of_study && <span className="text-danger">
                                    {errors.course_of_study[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Other Course Of Study</span>

                                <label className="mt-1">
                                    <i className="fa fa-chalkboard"></i>
                                    <input type="text" id="others_course_of_study" defaultValue={education?.others_course_of_study ?? ''} onChange={handleSetOthersCourseOfStudy} />
                                </label>

                                {errors.others_course_of_study && <span className="text-danger">
                                    {errors.others_course_of_study[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Beginning Date <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-calendar"></i>
                                    <input type="date" id="beginning_date" defaultValue={beginningDate ?? ''} onChange={handleSetBeginningDate} />
                                </label>

                                {errors.beginning_date && <span className="text-danger">
                                    {errors.beginning_date[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Ending Date <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-calendar"></i>
                                    <input type="date" id="ending_date" defaultValue={endingDate ?? ''} onChange={handleSetEndingDate} />
                                </label>

                                {errors.ending_date && <span className="text-danger">
                                    {errors.ending_date[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>State City <span style={{ color: '#ff8d00' }}>*</span></span>

                                <label className="mt-1">
                                    <i className="fa fa-flag"></i>
                                    <select style={{ paddingLeft: '2em' }} id="state_city" value={education.state_city?.id_city} onChange={handleSetStateCity}>
                                        <option defaultValue="">Select state</option>

                                        {cities.map(city => {
                                            return <option key={city.id_city} value={city.id_city} defaultChecked={education.state_city === city.id_city}>
                                                {city.en_US}
                                            </option>
                                        })}
                                    </select>
                                </label>

                                {errors.state_city && <span className="text-danger">
                                    {errors.state_city[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Other City</span>

                                <label className="mt-1">
                                    <i className="fa fa-flag"></i>
                                    <input type="text" id="other_city" defaultValue={education?.other_city ?? ''} onChange={handleSetOtherCity} />
                                </label>

                                {errors.other_city && <span className="text-danger">
                                    {errors.other_city[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Institution</span>

                                <label className="mt-1">
                                    <i className="fa fa-school"></i>
                                    <input type="text" id="institution" defaultValue={education?.institution ?? ''} onChange={handleSetInstitution} />
                                </label>

                                {errors.institution && <span className="text-danger">
                                    {errors.institution[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Degree Obtained <span style={{ color: '#ff8d00' }}>*</span></span>

                                <div className="d-flex align-items-center mt-2">
                                    <label className="d-flex flex-row align-items-center me-4" style={{ width: 'inherit' }}>
                                        <input type="radio" name="degree_obtained" value="1" checked={education?.degree_obtained === "1"} onChange={handleSetDegreeObtained} />
                                        <span className="ms-2">Yes</span>
                                    </label>

                                    <label className="d-flex flex-row align-items-center" style={{ width: 'inherit' }}>
                                        <input type="radio" name="degree_obtained" value="0" checked={education?.degree_obtained === "0"} onChange={handleSetDegreeObtained} />
                                        <span className="ms-2">No</span>
                                    </label>
                                </div>

                                {errors.degree_obtained && <span className="text-danger">
                                    {errors.degree_obtained[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6">
                                <span>Short Description</span>

                                <label className="mt-1">
                                    <i className="fa fa-pen"></i>
                                    <input type="text" id="short_description" defaultValue={education?.short_description ?? ''} onChange={handleSetShortDescription} />
                                </label>

                                {errors.short_description && <span className="text-danger">
                                    {errors.short_description[0]}
                                </span>}
                            </div>

                            <div className="col-lg-6"></div>

                            <div className="col-lg-6 mx-auto">
                                <label className="mb-0">
                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                            type="submit" onClick={handleSubmit}>Save changes</button>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
