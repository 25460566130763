import React, { Component } from 'react';
import Header from '../../components/layout/Header';
import PageHeader from "../../components/layout/PageHeader";
import { Footer } from '../../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {API_URL, HTTP_HEADERS, INVALID_DATA_RESPONSE_MESSAGE} from "../../config";
import SweetAlert from 'sweetalert2-react'

export default class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            privacy_policy: false,
            first_name: '',
            last_name: '',
            email: '',
            email_confirmation: '',
            password: '',
            password_confirmation: '',
            state_location: '',
            mobile_number: '',
            whatsapp_number: '',
            cities: [],
            errors: [],
            alert: {
                type: '',
                text: '',
                show: false,
                redirect: ''
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSetPrivacyPolicy = this.handleSetPrivacyPolicy.bind(this)
        this.handleSetFirstName = this.handleSetFirstName.bind(this)
        this.handleSetLastName = this.handleSetLastName.bind(this)
        this.handleSetEmail = this.handleSetEmail.bind(this)
        this.handleSetEmailConfirmation = this.handleSetEmailConfirmation.bind(this)
        this.handleSetPassword = this.handleSetPassword.bind(this)
        this.handleSetPasswordConfirmation = this.handleSetPasswordConfirmation.bind(this)
        this.handleSetStateLocation = this.handleSetStateLocation.bind(this)
        this.handleSetMobileNumber = this.handleSetMobileNumber.bind(this)
        this.handleSetWhatsAppNumber = this.handleSetWhatsAppNumber.bind(this)
    }

    componentDidMount() {
        fetch(`${API_URL}/cities`, {
            headers: HTTP_HEADERS()
        })
            .then(res => res.json())
            .then(data => this.setState({cities: data.data}))
    }

    handleSetWhatsAppNumber(e) {
        this.setState({whatsapp_number: e.target.value})
    }

    handleSetMobileNumber(e) {
        this.setState({mobile_number: e.target.value})
    }

    handleSetStateLocation(e) {
        this.setState({state_location: e.target.value})
    }

    handleSetPasswordConfirmation(e) {
        this.setState({password_confirmation: e.target.value})
    }

    handleSetEmailConfirmation(e) {
        this.setState({email_confirmation: e.target.value})
    }

    handleSetEmail(e) {
        this.setState({email: e.target.value})
    }

    handleSetPrivacyPolicy(e) {
        this.setState({privacy_policy: e.target.checked})
    }

    handleSetFirstName(e) {
        this.setState({first_name: e.target.value})
    }

    handleSetLastName(e) {
        this.setState({last_name: e.target.value})
    }

    handleSetPassword(e) {
        this.setState({password: e.target.value})
    }

    handleSubmit(e) {
        e.preventDefault()
        e.target.innerHTML = '<i class="fa fa-spinner fa-lg fa-spin me-1"></i> Sign up'

        fetch(`${API_URL}/register`, {
            method: 'post',
            headers: HTTP_HEADERS(),
            body: JSON.stringify({
                privacy_policy: this.state.privacy_policy,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                email_confirmation: this.state.email_confirmation,
                password: this.state.password,
                password_confirmation: this.state.password_confirmation,
                state_location: this.state.state_location,
                mobile_number: this.state.mobile_number,
                whatsapp_number: this.state.whatsapp_number,
            }),
        })
            .then(res => res.json())
            .then(data => {
                e.target.innerHTML = 'Sign up'

                if (data.message === INVALID_DATA_RESPONSE_MESSAGE) {
                    this.setState({errors: data.errors})
                } else {
                    this.setState({
                        alert: {
                            type: data.success === true ? 'success' : 'error',
                            text: data.message,
                            show: true,
                            redirect: `${process.env.PUBLIC_URL}/login`
                        }
                    })
                }
            })
    }

    render() {
        return <>
            <SweetAlert
                show={this.state.alert.show}
                text={this.state.alert.text}
                type={this.state.alert.type}
                onConfirm={() => document.location.href = this.state.alert.redirect}
            />

            <div className="site-main">
                <Header/>

                {/* PageHeader */}
                <PageHeader
                    title="register"
                    breadcrumb="register"
                />
                {/* PageHeader end */}


                {/* register */}
                <div className="ttm-row register-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <div className="text-center mb-20">
                                            <h3>Create An Account</h3>
                                        </div>
                                        <div className="ttm-tabs ttm-tab-style-02">
                                            <Tabs>
                                                <TabList className="tabs">
                                                    <Tab className="tab">
                                                        <a>
                                                            <i className="flaticon flaticon-research"></i>
                                                            <span>Candidate</span><h5>Login as a Candidate</h5>
                                                        </a>
                                                    </Tab>
                                                    <Tab className="tab">
                                                        <a>
                                                            <i className="flaticon flaticon-job-search"></i>
                                                            <span>Employer</span><h5>Login as a Employer</h5>
                                                        </a>
                                                    </Tab>
                                                </TabList>
                                                <div className="content-tab">
                                                    <TabPanel>
                                                        <form id="login_form" className="login_form wrap-form">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-user"></i>
                                                                        <input type="text" id="first_name" placeholder="First Name *" value={this.state.first_name} onChange={this.handleSetFirstName} />
                                                                    </label>

                                                                    {this.state.errors.first_name && <span className="text-danger">
                                                                        {this.state.errors.first_name[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-user"></i>
                                                                        <input type="text" id="last_name" placeholder="Last Name *" value={this.state.last_name} onChange={this.handleSetLastName} />
                                                                    </label>

                                                                    {this.state.errors.last_name && <span className="text-danger">
                                                                        {this.state.errors.last_name[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-email"></i>
                                                                        <input type="email" id="email" placeholder="Email Address *" value={this.state.email} onChange={this.handleSetEmail} />
                                                                    </label>

                                                                    {this.state.errors.email && <span className="text-danger">
                                                                        {this.state.errors.email[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-email"></i>
                                                                        <input type="email" id="email_confirmation" placeholder="Confirm Email Address *" value={this.state.email_confirmation} onChange={this.handleSetEmailConfirmation} />
                                                                    </label>

                                                                    {this.state.errors.email_confirmation && <span className="text-danger">
                                                                        {this.state.errors.email_confirmation[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-mobile"></i>
                                                                        <input type="tel" id="mobile_number" placeholder="Mobile Number *" value={this.state.mobile_number} onChange={this.handleSetMobileNumber}/>
                                                                    </label>

                                                                    {this.state.errors.mobile_number && <span className="text-danger">
                                                                        {this.state.errors.mobile_number[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-mobile"></i>
                                                                        <input type="tel" id="whatsapp_number" placeholder="WhatsApp Number *" value={this.state.whatsapp_number} onChange={this.handleSetWhatsAppNumber}/>
                                                                    </label>

                                                                    {this.state.errors.whatsapp_number && <span className="text-danger">
                                                                        {this.state.errors.whatsapp_number[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-lock"></i>
                                                                        <input type="password" id="password" placeholder="Password *" value={this.state.password} onChange={this.handleSetPassword}/>
                                                                    </label>

                                                                    {this.state.errors.password && <span className="text-danger">
                                                                        {this.state.errors.password[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-lock"></i>
                                                                        <input type="password" id="password_confirmation" placeholder="Confirm Password *" value={this.state.password_confirmation} onChange={this.handleSetPasswordConfirmation}/>
                                                                    </label>

                                                                    {this.state.errors.password_confirmation && <span className="text-danger">
                                                                        {this.state.errors.password_confirmation[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-flag"></i>
                                                                        <select style={{ paddingLeft: '2em' }} id="state_location" value={this.state.state_location} onChange={this.handleSetStateLocation} placeholder="State location">
                                                                            <option value="">State Location *</option>

                                                                            {this.state.cities.map(city => {
                                                                                return <option key={city.id_city} value={city.id_city}>
                                                                                    {city.en_US}
                                                                                </option>
                                                                            })}
                                                                        </select>
                                                                    </label>

                                                                    {this.state.errors.state_location && <span className="text-danger">
                                                                        {this.state.errors.state_location[0]}
                                                                    </span>}
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label className="mt-0">
                                                                        <div className="d-md-flex align-items-center justify-content-between">
                                                                            <div className="cookies-checkbox mt-15">
                                                                                <div className="d-flex flex-row justify-content-start">
                                                                                    <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" checked={this.state.privacy_policy} onChange={this.handleSetPrivacyPolicy} />
                                                                                    <span>You accept our Terms and Conditions and Privacy Policy</span>

                                                                                </div>

                                                                                {this.state.errors.privacy_policy && <span className="text-danger">
                                                                                    {this.state.errors.privacy_policy[0]}
                                                                                </span>}
                                                                            </div>
                                                                            <div className="mt-15">
                                                                                <p>Already registered?<a href={process.env.PUBLIC_URL + '/login'} className="text-theme-SkinColor"> Sign in here</a></p>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 mx-auto">
                                                                    <label className="mb-0">
                                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                                                type="submit" onClick={this.handleSubmit}>Sign up</button>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <form id="login_form" className="login_form wrap-form">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-user"></i>
                                                                        <input type="text" id="txtname" placeholder="Username" />
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-email"></i>
                                                                        <input type="email" id="txtemail" placeholder="Email Address" />
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-lock"></i>
                                                                        <input type="password" id="password" placeholder="Password"/>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>
                                                                        <i className="ti ti-lock"></i>
                                                                        <input type="password" id="cpassword" placeholder="Confirm Password *"/>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label>
                                                                        <i className="ti ti-mobile"></i>
                                                                        <input type="tel" id="txtphone" placeholder="Phone Number"/>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label className="mt-0">
                                                                        <div className="d-md-flex align-items-center justify-content-between">
                                                                            <div className="cookies-checkbox mt-15">
                                                                                <div className="d-flex flex-row justify-content-start">
                                                                                    <input className="w-auto mr-10 mt-5" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" />
                                                                                    <span>you accept our Terms and Conditions and Privacy Policy</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-15">
                                                                                <p>Already have an account?</p>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 mx-auto">
                                                                    <label className="mb-0">
                                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                                                type="submit">Sign up</button>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </TabPanel>
                                                </div>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* login end */}

                <Footer/>
            </div>
        </>
    }
}
